import agencySearchService from "./agencySearchService";

export const BILLING_AGENCY_SEARCH_RESULTS = "BILLING_AGENCY_SEARCH_RESULTS";

export function billingAgencySearch({ agencyName, marketId, altAgencyId, billAndRemit }) {
  return async dispatch => {
    const response = await agencySearchService.agencySearch({
      agencyName,
      marketId,
      altAgencyId,
      billAndRemit
    });
    dispatch({
      type: BILLING_AGENCY_SEARCH_RESULTS,
      payload: response.result
    });
  };
}
