import _ from "lodash";
import { AD_TYPES_CPM_CONFIG, RESET_REDUCER_DATA } from "./metaActions";
const initialState = {
  adTypes: [],
  adTypeWithCpmConfig: null,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  switch (action.type) {
    case AD_TYPES_CPM_CONFIG: {
      const newState = { ...state };
      newState.adTypeWithCpmConfig = action.payload;
      return { ...newState };
    }

    case RESET_REDUCER_DATA: {
      const newState = _.cloneDeep(initialState);
      return { ...newState };
    }

    default:
      return state;
  }
};
