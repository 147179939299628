import { useState, useEffect, useReducer } from "react";
import queryString from "query-string";
import { useCookies } from "react-cookie";
import { Auth, Hub } from "aws-amplify";

/**
 * redirect - string - Optional. If defined, will be returned back to the caller at the end of the login process if successful. It's up to the caller to redirect the user
 * debugMode - bool - Optional. If true, will output debug statements to the javascript console
 * identityProvider - string (required) - Forces Amlify/Cognito to show the login for a specific identity provider
 *
 * @export
 * @param {*} { redirect, debugMode, identityProvider }
 * @returns
 */
export function useCognito({ redirect, debugMode, identityProvider }) {
  function _log(message, params) {
    if (debugMode) {
      console.log(message, params);
    }
  }

  function _group(state) {
    if (debugMode) {
      const today = new Date();
      const time =
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds() +
        "." +
        today.getMilliseconds();
      console.groupCollapsed("Action " + state + " @ " + time);
    }
  }

  function _endGroup() {
    if (debugMode) {
      console.groupEnd();
    }
  }

  const states = {
    START: "START",
    TRY_TOKEN: "TRY_TOKEN",
    FEDERATED_SIGN_IN: "FEDERATED_SIGN_IN",
    CHECK_COGNITO_USER: "CHECK_COGNITO_USER",
    TRY_REFRESH_TOKEN: "TRY_REFRESH_TOKEN",
    SUCCESS: "SUCCESS",
    TOKEN_ERROR: "TOKEN_ERROR"
  };

  const initialState = {
    state: states.START
  };

  function reducer(state, action) {
    _group(action.state);
    _log("Prev state:", state);
    _log("Action:", action);
    let newState = {};
    switch (action.state) {
      case states.START:
      case states.TRY_TOKEN:
      case states.FEDERATED_SIGN_IN:
      case states.CHECK_COGNITO_USER:
      case states.TRY_REFRESH_TOKEN:
      case states.SUCCESS:
      case states.TOKEN_ERROR:
        newState = { ...action };
        break;
      default:
        throw new Error(action.state);
    }
    _log("Next state:", newState);
    _endGroup();
    return newState;
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [retVal, setRetVal] = useState({ done: false, success: false });
  const [cookies, , removeCookie] = useCookies();

  const { search } = window.location;

  useEffect(() => {
    //Internal Functions
    function log(message) {
      if (debugMode) {
        console.log(message);
      }
    }

    async function federatedSign(code) {
      //If is mandatory due to redirect before user session is saved
      if (cookies["amplify-redirected-from-hosted-ui"] && code) {
        Hub.listen("auth", ({ payload: { event } }) => {
          if (event === "signIn") {
            dispatch({ state: states.TRY_TOKEN });
          } else if (event === "signIn_failure") {
            removeCookie("amplify-redirected-from-hosted-ui");
            dispatch({ state: states.FEDERATED_SIGN_IN });
          }
        });
      } else {
        try {
          await Auth.federatedSignIn({ customProvider: identityProvider });
          return Auth.currentAuthenticatedUser();
        } catch (e) {
          log(e);
        }
      }
    }

    function getRedirect(state) {
      if (redirect) return redirect;
      const decoded = decodeURIComponent(state);
      const params = queryString.parse(decoded);
      return params.redirect;
    }

    async function tryGetUser(next) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(user);
        const userAttrs = attributes.map(attr => {
          return { name: attr.getName(), value: attr.getValue() };
        });
        const params = queryString.parse(search);
        const { state } = params;
        const redirectTo = getRedirect(state);
        dispatch({ state: states.SUCCESS, attributes: userAttrs, redirectTo });
      } catch (e) {
        log(e);
        dispatch({ state: next });
      }
    }

    async function tryRefreshSession() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
          log(`session ${err} ${session}`);
          await tryGetUser(states.FEDERATED_SIGN_IN);
        });
      } catch (e) {
        log(`Unable to refresh Token ${e}`);
        log("Can't refresh, cleanup and redirect to login");
        removeCookie("amplify-redirected-from-hosted-ui");
        dispatch({ state: states.FEDERATED_SIGN_IN });
      }
    }

    async function checkCognitoUser() {
      try {
        await Auth.currentAuthenticatedUser();
        dispatch({ state: states.TRY_TOKEN });
      } catch {
        dispatch({ state: states.FEDERATED_SIGN_IN });
      }
    }

    //End Internal Functions
    const params = queryString.parse(search);
    const { code } = params;

    switch (state.state) {
      case states.START:
        dispatch({ state: states.CHECK_COGNITO_USER });
        break;
      case states.TRY_TOKEN:
        tryGetUser(states.TRY_REFRESH_TOKEN);
        break;
      case states.CHECK_COGNITO_USER:
        checkCognitoUser();
        break;
      case states.TRY_REFRESH_TOKEN:
        tryRefreshSession();
        break;
      case states.FEDERATED_SIGN_IN:
        federatedSign(code);
        break;
      case states.SUCCESS:
        setRetVal({ done: true, success: true, ...state });
        break;
      case states.TOKEN_ERROR:
        setRetVal({ done: true, success: false, ...state });
        break;
      default:
        throw new Error(state.state);
    }
  }, [
    cookies,
    debugMode,
    redirect,
    search,
    state,
    removeCookie,
    setRetVal,
    states.CHECK_COGNITO_USER,
    states.FEDERATED_SIGN_IN,
    states.START,
    states.SUCCESS,
    states.TOKEN_ERROR,
    states.TRY_REFRESH_TOKEN,
    states.TRY_TOKEN,
    identityProvider
  ]);

  return retVal;
}
