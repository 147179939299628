import React from "react";
import PropTypes from "prop-types";
import "./landingPage.scss";

function LandingPageContent(props) {
  return (
    <main className={props.classes.content}>
      <div className={props.classes.toolbar} />
      <div>{props.children}</div>
    </main>
  );
}

LandingPageContent.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default LandingPageContent;
