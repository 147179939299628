import { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ADD_ORIGIN } from "../login-page/loginActions";

export default function IFrameListener() {
  const dispatch = useDispatch();
  const { isLoggedIn, isStandalone, origin } = useSelector(state => state.loginData);
  const { isUnsavedData } = useSelector(state => state.mspViewState);

  useLayoutEffect(() => {
    if (!isStandalone && isLoggedIn) {
      window.parent.postMessage(
        {
          type: "READY",
          payload: {}
        },
        origin
      );
    }
  }, [isStandalone, isLoggedIn, origin]);

  useLayoutEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
      if (event.data.type === ADD_ORIGIN) {
        dispatch({ type: ADD_ORIGIN, payload: { origin: event.origin } });
      }
    }
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, [dispatch]);

  useLayoutEffect(() => {
    window.parent.postMessage(
      {
        type: "VIEW_STATE_UPDATE",
        payload: { isUnsavedData }
      },
      origin
    );
  }, [isUnsavedData, origin]);
  return null;
}
