export const config = {
  serverURL: "https://pi9os2qjhi.execute-api.us-east-1.amazonaws.com/radigio/api/v1.0",
  NXTServerURL: "https://{tenant}.mediascape.marketron.com", // TODO: to be changed to NXT
  gaTrackingId: "UA-39909867-2",
  gaDebug: false,
  environment: "prod",
  clientId: "kftn6ufvfbmov3slqen48rgvl",
  cognitoDomain: "emarketron.auth.us-east-1.amazoncognito.com",
  identityProvider: "Emarketron.net",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d7",
  uploadCreativeServerUrl:
    "https://radigio-alb-production.marketron.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_xEjIeo2GJ",
};
