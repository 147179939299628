import _ from "lodash";

import { LOAD_ORDER_LOOKUPS } from "./orderLookupsActions";
import { ORDER_INVOICE } from "../../../property/constants/orderConstants";

const initialState = {
  marketId: undefined,
  lookups: {},
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  if (action.type === LOAD_ORDER_LOOKUPS) {
    return action.payload;
  }
  return state;
};

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Return a lookup fro order lookups with filtering
 *
 * @export
 * @param {*} state
 * @param {*} lookupName
 * @param {*} [defaultValue=[]]
 * @returns
 */
export function getLookup(state, lookupName, defaultValue = [], currentValue = undefined) {
  const { marketId } = state.orderLookups;
  const data = state.orderLookups.lookups[lookupName];
  const isDirect =
    _.get(
      state,
      "createOrderHeaderData.summaryAdTypes.summary.businessType.value",
      ORDER_INVOICE.BUSINESS_TYPE.DIRECT_VALUE
    ) === ORDER_INVOICE.BUSINESS_TYPE.DIRECT_VALUE;
  if (!data || data.length === 0) return defaultValue;

  if (lookupName === "commissions") {
    const commission = state.orderLookups.lookups["commissions"];
    if (commission.default_commissions) {
      return commission.default_commissions;
    } else {
      return [];
    }
  } else if (lookupName === "revenueTypes") {
    return _.reduce(
      data,
      (memo, item) => {
        const isValid =
          item.active &&
          item.global_revenue_class_id === 0 &&
          item.revenue_type_markets.find(
            (market) =>
              market.active &&
              market.market_id === marketId &&
              (market.business_type === ORDER_INVOICE.BUSINESS_TYPE.ALL ||
                (isDirect
                  ? market.business_type === ORDER_INVOICE.BUSINESS_TYPE.DIRECT_VALUE
                  : market.business_type === ORDER_INVOICE.BUSINESS_TYPE.AGENCY))
          );
        if (isValid || (currentValue && parseInt(item.id, 10) === parseInt(currentValue, 10))) {
          if (isValid) {
            memo.push(item);
          } else {
            const itemCopy = { ...item };
            itemCopy.name = "(I) " + itemCopy.name;
            memo.push(itemCopy);
          }
        }
        return memo;
      },
      []
    );
  } else {
    let list = [];
    let dataMarkets = undefined;
    switch (lookupName) {
      case "billingCycles":
      case "commissions":
      case "revenue_classes":
      case "revenue_categories":
      case "standardRemarks":
      case "tax_schedules":
      case "tenant_defaults":
        dataMarkets = undefined;
        break;
      case "inventoryCategories":
        dataMarkets = "inventory_category_markets";
        break;
      case "inventoryTypes":
        dataMarkets = "inventory_type_markets";
        break;
      case "makeGoodPolicies":
        dataMarkets = "make_good_policy_markets";
        break;
      case "competivieTypes":
        dataMarkets = "competitive_type_markets";
        break;
      case "salespersons":
        dataMarkets = "traffic_users";
        break;
      case "spotLengths":
        dataMarkets = "spot_length_outlets";
        break;
      // standardRemoarks wont work properly becuase where they are used is not passing
      // a currentValue to properly filter and include currently selected values.
      // case "standardRemarks":
      //   dataMarkets = "standard_remark_markets";
      //   break;
      default:
        dataMarkets = camelToSnakeCase(lookupName) + "_markets";
        break;
    }
    if (
      dataMarkets !== undefined &&
      data[0].active !== undefined &&
      data[0][dataMarkets] !== undefined &&
      data[0][dataMarkets][0] !== undefined &&
      data[0][dataMarkets][0].active !== undefined &&
      marketId !== undefined
    ) {
      // The lookup has market scoped children with an active param. Use that to filter the lookup
      list = data.filter((item) => {
        return (
          item.active &&
          item[dataMarkets].filter((market) => market.market_id === marketId && market.active)
            .length > 0
        );
      });
    } else if (data[0].active !== undefined) {
      // The lookup has an active param. Use that to filter the lookup
      list = data.filter((item) => item.active);
    } else {
      //No filters found, just return the lookup
      list = data;
    }

    if (!currentValue) {
      return list;
    }

    //We have a currentValue. Need to make sure its in the list and if not add it is "inactive"
    if (list.find((item) => parseInt(item.id, 10) === parseInt(currentValue, 10))) {
      //The current value item is in the list, so we can safely return the list
      return list;
    }
    //Add the item to the list
    const ret = [];
    const originalItem = data.find((item) => parseInt(item.id, 10) === parseInt(currentValue, 10));
    if (originalItem) {
      const item = { ...originalItem };
      item.name = "(I) " + item.name;
      ret.push(item);
    }
    list.forEach((item) => ret.push(item));
    return ret;
  }
}
