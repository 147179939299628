import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import AnalyticsIcon from "../../assets/analytics-icon.png";
import CampaignIcon from "../../assets/campaign-icon.png";
import CreativeManagementIcon from "../../assets/creative-management-icon.png";
import FeedbackIcon from "../../assets/feedback-icon.png";
import OrderIcon from "../../assets/order-icon.png";
import ProposalIcon from "../../assets/proposal-icon.png";
import { PROPOSAL_CONSTANTS } from "../../property/constants/proposalConstants";
import "./landingPage.scss";

function LandingPageDrawer(props) {
  return (
    <Drawer
      variant="permanent"
      className={classNames(props.classes.drawer, {
        [props.classes.drawerOpen]: props.open,
        [props.classes.drawerClose]: !props.open
      })}
      classes={{
        paper: classNames({
          [props.classes.drawerOpen]: props.open,
          [props.classes.drawerClose]: !props.open
        })
      }}
      open={props.open}
    >
      <div className={props.classes.toolbar}>
        <IconButton onClick={props.handleDrawerClose}>
          {props.theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List className={props.menuClassName}>
        <ListItem button>
          <ListItemIcon>
            <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.PROPOSAL_URI}>
              <img src={ProposalIcon} alt="Proposal Icon" />
            </Link>
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.PROPOSAL_URI}>
                <span className={"text-size " + props.activelink}>
                  {PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.PROPOSAL}
                </span>
              </Link>
            }
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <img src={CreativeManagementIcon} alt="Creative Management Icon" />
          </ListItemIcon>
          <ListItemText
            primary={
              <span className="text-size">
                {PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.CREACTIVE_MANAGEMENT}
              </span>
            }
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.ORDERS_URI}>
              <img src={OrderIcon} alt="Order Icon" />
            </Link>
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.ORDERS_URI}>
                <span className="text-size">{PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.ORDERS}</span>
              </Link>
            }
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Link to="/orders/128849019659/lines/display/save">
              <img src={OrderIcon} alt="Order Icon" />
            </Link>
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to="/orders/128849019659/lines/display/save">
                <span className="text-size">Order Lines</span>
              </Link>
            }
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Link to="/orders/128849019659/lines/video/save">
              <img src={OrderIcon} alt="Order Icon" />
            </Link>
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to="/orders/128849019659/lines/video/save">
                <span className="text-size">Order Lines</span>
              </Link>
            }
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.CAMPAIGNS_URI}>
              <img src={CampaignIcon} alt="Campaign Icon" />
            </Link>
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to={PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.CAMPAIGNS_URI}>
                <span className="text-size">{PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.CAMPAIGNS}</span>
              </Link>
            }
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <img src={AnalyticsIcon} alt="Analytics Icon" />
          </ListItemIcon>
          <ListItemText
            primary={
              <span className="text-size">{PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.ANALYTICS}</span>
            }
          />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <img src={FeedbackIcon} alt="Feedback Icon" />
          </ListItemIcon>
          <ListItemText
            primary={
              <span className="text-size">{PROPOSAL_CONSTANTS.LANDINGPAGE_LABELS.FEEDBACK}</span>
            }
          />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
}

LandingPageDrawer.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
  theme: PropTypes.object,
  menuClassName: PropTypes.string,
  showProposals: PropTypes.func,
  showOrders: PropTypes.func,
  showCampaigns: PropTypes.func,
  activelink: PropTypes.string
};

export default LandingPageDrawer;
