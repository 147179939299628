import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

function agencySearch({ marketId, agencyName, altAgencyId, billAndRemit }) {
  let url = `${URL.AGENCY_SEARCH}?page=1&perPage=10&agencyName=${agencyName}&marketId=${marketId}&billAndRemit=${billAndRemit}`;

  if (altAgencyId) {
    url += `&excludedAgencyId=${altAgencyId}`;
  }

  return request({
    url: url,
    method: "GET",
    json: true
  });
}

const agencySearchService = {
  agencySearch
};

export default agencySearchService;
