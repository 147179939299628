import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

function orderLookups(marketId) {
  return request({
    url: `${URL.ORDERS_LOOKUPS}/${marketId}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    json: true
  });
}

const orderLookupsService = {
  orderLookups
};

export default orderLookupsService;
