import { useDispatch } from "react-redux";

import useUserPreferences from "./useUserPreferences";
import { LOAD_USER_PREFERENCES } from "./UserPreferencesReducer";

export default function UserPreferences() {
  const payload = useUserPreferences();
  const dispatch = useDispatch();

  dispatch({ type: LOAD_USER_PREFERENCES, payload });

  return null;
}
