import advertiserSearchService from "./advertiserSearchService";

export const ADVERTISER_SEARCH_RESULTS = "ADVERTISER_SEARCH_RESULTS";
export const LOOKUP_ADVERTISER_SEARCH_RESULTS = "LOOKUP_ADVERTISER_SEARCH_RESULTS";
export const LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING = "LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING";

export function advertiserSearch({ advertiserName, marketId, isDirect, billAndRemit }) {
  return async dispatch => {
    const response = await advertiserSearchService.advertiserSearch({
      advertiserName,
      marketId,
      isDirect,
      billAndRemit
    });
    dispatch({
      type: ADVERTISER_SEARCH_RESULTS,
      payload: response.result
    });
    dispatch({
      type: LOOKUP_ADVERTISER_SEARCH_RESULTS,
      payload: response.result
    });
  };
}

export function lookupAdvertiserSearch({
  advertiserName,
  marketId,
  businessType,
  billAndRemit,
  prospectAccounts,
  advertiserAddress,
  advertiserNameType,
  advertiserAddressType,
  page,
  perPage
}) {
  return async dispatch => {
    dispatch({
      type: LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING,
      payload: true
    });
    const response = await advertiserSearchService.lookupAdvertiserSearch({
      advertiserName,
      marketId,
      businessType,
      billAndRemit,
      prospectAccounts,
      advertiserAddress,
      advertiserNameType,
      advertiserAddressType,
      page,
      perPage
    });
    dispatch({
      type: LOOKUP_ADVERTISER_SEARCH_RESULTS,
      payload: response.result
    });
    dispatch({
      type: ADVERTISER_SEARCH_RESULTS,
      payload: response.result
    });
    dispatch({
      type: LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING,
      payload: false
    });
  };
}
