import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

export const LOAD_USER_PREFERENCES = "LOAD_USER_PREFERENCES";
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";

const initialState = {
  proposals_view_option: 0,
  show_proposal_view_options: true,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  switch (action.type) {
    case LOAD_USER_PREFERENCES:
      return { ...action.payload };
    case UPDATE_USER_PREFERENCES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const updateUserPreferences = ({ show_proposal_view_options, proposals_view_option }) => {
  return async (dispatch) => {
    await request({
      url: `${URL.USER}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        user_preference: {
          show_proposal_view_options,
          proposals_view_option,
        },
      },
      json: true,
    });
    dispatch({
      type: UPDATE_USER_PREFERENCES,
      payload: { show_proposal_view_options, proposals_view_option },
    });
  };
};
