import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import DownArrow from "../../../assets/button_closed_section.svg";
import DownArrowActive from "../../../assets/button_expand_section.svg";
import Grid from "@material-ui/core/Grid";
import "./expansion.scss";

const AppExpansionPanel = props => {
  const expandedStatusIcon =
    props.expandedStatus || props.expansionPanelError ? DownArrowActive : DownArrow;
  return (
    <ExpansionPanel
      className="expansion-panel"
      expanded={props.expansionPanelError || props.expandedStatus}
      onChange={props.handleExpansion}
    >
      <ExpansionPanelSummary
        className="expansion-summary-heading"
        expandIcon={<img src={expandedStatusIcon} alt="expandIcon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-cy={props.cy || ""}
      >
        <span
          className={`heading-text-size   ${
            props.expansionPanelError ? "expansion-panel-error" : props.expansionActiveLink
          }`}
        >
          {props.heading}
        </span>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className="expansion-details">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="expansion-panel-section">{props.expansionPanelDetails}</div>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AppExpansionPanel;
