import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

export default function Authenticated({ children }) {
  const isLoggedIn = useSelector(state => state.loginData.isLoggedIn);
  const location = useLocation();
  const history = useHistory();

  if (!isLoggedIn) {
    const uri =
      location.pathname !== "" || location.pathname !== "/"
        ? `?uri=${encodeURIComponent(location.pathname)}`
        : "";
    history.push(`/login${uri}`);
  }

  if (isLoggedIn) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}
