import orderLookupsService from "./orderLookupsService";
import { API_STATUS } from "../../../property/constants/commonConstants";

export const LOAD_ORDER_LOOKUPS = "LOAD_ORDER_LOOKUPS";

export const loadOrderLookups = ({ marketId }) => {
  return async dispatch => {
    const response = await orderLookupsService.orderLookups(marketId);
    if (response.status === API_STATUS.SUCCESS) {
      dispatch({
        type: LOAD_ORDER_LOOKUPS,
        payload: {
          marketId,
          lookups: response.result
        }
      });
    }
  };
};
