import {
  TOKEN_LOGIN_SUCCESS,
  ADD_ORIGIN,
  SET_MISSING_REPORT_DATA_DIALOG_OPEN,
} from "./loginActions";

const initialState = {
  loginError: false,
  isLoggedIn: false,
  isStandalone: true,
  errorMessage: undefined,
  user: undefined,
  origin: "*",
  missingReportDataDialogOpen: false,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  switch (action.type) {
    case TOKEN_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isStandalone: false,
        user: action.payload,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        isStandalone: true,
        user: action.payload,
      };
    case "LOGIN_FAIL":
    case "VALIDATION_FAIL":
      return {
        ...state,
        loginError: action.loginError,
        errorMessage: action.errorMessage,
      };
    case ADD_ORIGIN:
      return {
        ...state,
        origin: action.payload.origin,
      };
    case SET_MISSING_REPORT_DATA_DIALOG_OPEN:
      return {
        ...state,
        missingReportDataDialogOpen: action.payload.missingReportDataDialogOpen,
      };
    default:
      return state;
  }
};
