export const config = {
  serverURL: "https://api-nxtdev22.apps.mktnxt.com/api/v1.0",
  NXTServerURL: "https://{tenant}.mediascape-nxtdev22.apps.mktnxt.com",
  gaTrackingId: "UA-39909867-5",
  gaDebug: false,
  environment: "nxtqa1",
  clientId: "2g49t4a9jva5shdihp60fr6789",
  cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
  identityProvider: "marketron-dev.com",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
  uploadCreativeServerUrl: "https://radigio-alb-dev.mediascape-dev.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_s6bRlZd0d",
};
