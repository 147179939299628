import _ from "lodash";
import {
  LOOKUP_ADVERTISER_SEARCH_RESULTS,
  LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING,
} from "./advertiserSearchActions";

const initialState = {
  advertisers: [],
  perPage: 10,
  total: 0,
  page: 1,
  loading: false,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  if (action.type === LOOKUP_ADVERTISER_SEARCH_RESULTS) {
    if (action.payload) {
      const payload = _.cloneDeep(action.payload);
      if (_.get(payload, "page", 1) === 1) {
        return payload;
      } else {
        const stateWithPage = _.cloneDeep(state);
        stateWithPage.advertisers = _.concat(
          stateWithPage.advertisers,
          _.get(payload, "advertisers")
        );
        stateWithPage.perPage = _.get(payload, "perPage", 10);
        stateWithPage.total = _.get(payload, "total", 0);
        stateWithPage.page = _.get(payload, "page", 1);
        return stateWithPage;
      }
    } else return state;
  } else if (action.type === LOOKUP_ADVERTISER_SEARCH_RESULTS_LOADING) {
    const stateWithLoader = _.cloneDeep(state);
    stateWithLoader.loading = action.payload;
    return stateWithLoader;
  }
  return state;
};
