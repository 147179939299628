export const notificationConstants = {
  TYPE: {
    ERROR: "error",
    WARNING: "warning",
    SUCCESS: "success",
    INFO: "info"
  },
  MESSAGE: "",
  TOP_BOTTOM: "top",
  LEFT_RIGHT: "center"
};
