export const config = {
  serverURL: "https://xdub3z17n6.execute-api.us-east-1.amazonaws.com/staging/api/v1.0",
  NXTServerURL: "https://{tenant}.staging.mktnxt.com",
  gaTrackingId: "UA-39909867-5",
  gaDebug: false,
  environment: "staging",
  clientId: "gc1n1uiohdipc2hvdutnbho6d",
  cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
  identityProvider: "marketron-dev.com",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
  uploadCreativeServerUrl:
    "https://radigio-alb-staging.mediascape-dev.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_s6bRlZd0d",
};
