//GA and Datadog are turned off for local development
//Server url can still use the "old" style REACT_APP_SERVER_URI without DEV but in general defaults to dev

export const config = {
  serverURL: process.env.REACT_APP_SERVER_URI || "http://localhost:30303",
  NXTServerURL: "http://{localhost}",
  gaTrackingId: undefined,
  gaDebug: true,
  clientId: "6e76kgi80jm2rmgfr46btkjn5g",
  cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
  identityProvider: "marketron-dev.com",
  environment: "local",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
  uploadCreativeServerUrl: "https://radigio-alb-dev.mediascape-dev.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_s6bRlZd0d",
};
