import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { config } from "../../api-config/config";
import { useCognito } from "./useCognito";
import bg_oval from "../../assets/bg_oval.png";
import bg_art_network from "../../assets/bg1.png";
import loginService from "./loginService";
import "./login.scss";

export default function Login() {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.loginData.errorMessage);
  const history = useHistory();
  const { search } = history.location;
  const { cognitoDomain, clientId, identityProvider } = config;
  const params = queryString.parse(search);
  const redirect = params.uri ? params.uri : "/";

  const cognitoParams = {
    debugMode: true,
    redirect,
    identityProvider,
  };

  const { done, success, redirectTo, attributes } = useCognito(cognitoParams);

  useEffect(() => {
    async function loginFromCognito({ tenant_name, userName, redirectTo, history }) {
      let error = false;
      let errorMessage;
      try {
        const response = await loginService.loginUser({ tenant_name, userName });
        if (response.status === "success") {
          let token = `${tenant_name}::${response.result.accessToken}`;
          const userResponse = await loginService.validateToken(token);
          if (userResponse) {
            token = `${userResponse.result.data.tenant_id}::${response.result.accessToken}`;
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("tenant", tenant_name);
            const payload = { token, ...userResponse.result };
            dispatch({
              type: "LOGIN_SUCCESS",
              payload: payload,
            });
            history.push(redirectTo ? redirectTo : "/proposals");
          } else {
            error = true;
            errorMessage = "Unknown error";
          }
        } else {
          error = true;
          errorMessage = response.error.description;
        }
      } catch (e) {
        console.error(e);
        error = true;
        errorMessage = e.errorMessage;
      }

      if (error) {
        sessionStorage.removeItem("token");
        dispatch({
          type: "LOGIN_FAIL",
          loginError: true,
          errorMessage,
        });
      }
    }

    if (done && success) {
      loginFromCognito({
        tenant_name: attributes.find((attr) => attr.name === "locale")?.value,
        userName: attributes.find((attr) => attr.name === "email")?.value,
        redirectTo,
        history,
      });
    } else {
      sessionStorage.removeItem("token");
    }
  }, [done, success, attributes, history, dispatch, errorMessage, redirectTo]);

  const { port, protocol, hostname } = window.location;
  const _port = port !== "" ? `:${window.location.port}` : "";
  const redirect_uri = `${protocol}//${hostname}${_port}/logout`;
  const logoutHref = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirect_uri}`;

  return (
    <div className="main-panel">
      <div className="loginForm">
        <div className="login-form-container">
          <Card className="login-card">
            <CardContent className="card-login-form">
              {errorMessage &&
                (errorMessage === "Invalid username or password" ||
                  errorMessage === "Expired token") && (
                  <div>
                    Your username does not exist in Marketron NXT. If you switched between non
                    production environments such as between "dev" and "staging", click{" "}
                    <a href={logoutHref}>here</a> to retry.
                  </div>
                )}
              {errorMessage && errorMessage !== "Invalid username or password" && (
                <div>Error logging into to Marketron NXT: {errorMessage}</div>
              )}
              {!done && !errorMessage && <div>Loading...</div>}
              {done && !success && (
                <div>
                  Unknown error occurred, click <a href={logoutHref}>here</a> to retry.
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="bg-layer">
        <div className="bg-art-network">
          <img src={bg_art_network} alt="Smiley face"></img>
        </div>
        <div className="bg-oval-bottom">
          <img src={bg_oval} alt="Smiley face"></img>
        </div>
      </div>
    </div>
  );
}
