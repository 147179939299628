export function dynamicConfig(hostname) {
  const isLocalApi = hostname.indexOf("-local-") !== -1;
  const namespace = isLocalApi
    ? hostname.replace("digital-frontend-local-", "").replace(".mktnxt.net", "")
    : hostname.replace("digital-frontend-", "").replace(".mktnxt.net", "");
  let serverURL;
  if (process.env.REACT_APP_SERVER_URI) {
    serverURL = process.env.REACT_APP_SERVER_URI;
  } else {
    serverURL = isLocalApi
      ? "http://localhost:3030"
      : `https://digital-api-${namespace}.mktnxt.net`;
  }
  return {
    serverURL,
    NXTServerURL: `https://{tenant}-mediascape-${namespace}.mktnxt.net`,
    gaTrackingId: "UA-39909867-5",
    gaDebug: false,
    environment: "qa1",
    clientId: "9ep27lregumtmqbhrlntir7gf",
    cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
    identityProvider: "marketron-dev.com",
    launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
    uploadCreativeServerUrl: `https://digital-api-${namespace}.mktnxt.net/creatives`,
    userPoolId: "us-east-1_s6bRlZd0d",
  };
}
