const initialState = {
  isUnsavedData: false,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  switch (action.type) {
    case "VIEW_STATE_CHANGED": {
      const newState = { ...state };
      newState.isUnsavedData = action.viewStateChanged;
      return { ...newState };
    }
    default:
      return state;
  }
};
