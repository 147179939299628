import loginService from "./loginService";

export const TOKEN_LOGIN_SUCCESS = "TOKEN_LOGIN_SUCCESS";
export const ADD_ORIGIN = "ADD_ORIGIN";
export const SET_MISSING_REPORT_DATA_DIALOG_OPEN = "SET_MISSING_REPORT_DATA_DIALOG_OPEN";

export const loginFromMsp = ({ tenant_id, token }) => {
  return async dispatch => {
    try {
      const mspToken = `${tenant_id}::${token}`;
      sessionStorage.setItem("token", mspToken);
      const userResponse = await loginService.validateToken(mspToken);
      if (userResponse.status === "success") {
        const payload = { token, ...userResponse.result };
        sessionStorage.setItem("tenant", payload.data.tenant_name);
        dispatch({
          type: TOKEN_LOGIN_SUCCESS,
          payload: payload
        });
      } else {
        sessionStorage.removeItem("token");
        dispatch({
          type: "LOGIN_FAIL",
          loginError: true
        });
      }
    } catch (e) {
      sessionStorage.removeItem("token");
      dispatch({
        type: "LOGIN_FAIL",
        loginError: true
      });
    }
  };
};
