import { LOAD_CREATIVE_DIMENSIONS } from "./creativeDimensionsAction";

const initialState = [];

export const reducer = function (state, action) {
  if (!state) state = initialState;
  if (action.type === LOAD_CREATIVE_DIMENSIONS) {
    return action.payload;
  }
  return state;
};
