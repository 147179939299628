import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/Marketron-logo.svg";
import "./landingPage.scss";
import UserProfile from "./UserProfile.js";

function LandingPageToolBar(props) {
  return (
    <AppBar
      position="fixed"
      className={classNames(
        props.classes.appBar,
        {
          [props.classes.appBarShift]: props.open
        },
        props.classes.colorPrimary
      )}
    >
      <Toolbar className={classNames(props.classes.regular, "landing-toolbar")}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.handleDrawerOpen}
          className={classNames(
            props.classes.menuButton,
            {
              [props.classes.hide]: props.open
            },
            "menu-button"
          )}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="Marketron Logo" width="auto" height="30"></img>
        <div className="header-action">
          <UserProfile />
        </div>
      </Toolbar>
    </AppBar>
  );
}

LandingPageToolBar.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  theme: PropTypes.object,
  handleDrawerOpen: PropTypes.func
};
export default LandingPageToolBar;
