import React from "react";
import ReactMapboxGl, { ZoomControl, Marker, Layer, Feature, GeoJSONLayer } from "react-mapbox-gl";
import pin from "../../assets/icon_location_marker.svg";
import _ from "lodash";
import { config as configData } from "../../../src/api-config/config";
import { PROPOSAL_CONSTANTS } from "../../property/constants/proposalConstants";
import * as mapBoxHelper from "./mapBoxHelper";
import axios from "axios";
import { MapBoxFailure } from "./MapBoxFailure";

class MapBoxGl extends React.Component {
  state = {
    mapBox: null,
    error: false,
    mapboxError: false,
  };
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.loadMap;
  }

  componentDidMount() {
    this.getMap();
  }

  getPolygonBoundingBox = (feature) => {
    // bounds [xMin, yMin][xMax, yMax]
    let bounds = [[], []];
    let polygon;
    let latitude;
    let longitude;

    for (let i = 0; i < feature.geometry.coordinates.length; i++) {
      if (feature.geometry.type === "MultiPolygon") {
        // Polygon coordinates[0][nodes]
        polygon = feature.geometry.coordinates[i][0];
      } else {
        // Polygon coordinates[poly][0][nodes]
        polygon = feature.geometry.coordinates[0];
      }

      for (let j = 0; j < polygon.length; j++) {
        longitude = polygon[j][0];
        latitude = polygon[j][1];
        if (longitude) {
          bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
          bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
        }
        if (latitude) {
          bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
          bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
        }
      }
    }
    return bounds;
  };

  onZoomMap = (ev) => {
    if (!ev.fitboundUpdate && this.props.mapZoom) {
      this.props.mapZoom(ev.target);
    }
  };

  getMap = async () => {
    // sent a GET request to ensure mapbox is online and authenticatake and set State
    axios
      .get(
        `https://api.mapbox.com/styles/v1/mapbox/streets-v8?access_token=${configData.mapBoxAccessToken}`
      )
      .then((response) => {
        this.setState({ mapBox: true });
        this.forceUpdate();
      })
      .catch((e) => {
        console.log(e);
        this.setState({ mapboxError: true });
        this.forceUpdate();
        if (this.props.handleMapboxDown !== undefined) {
          this.props.handleMapboxDown();
        }
      });
  };

  render() {
    if (this.state.mapboxError || this.props.locationTargettingError) {
      return (
        <MapBoxFailure
          message={
            this.props.locationTargettingError
              ? PROPOSAL_CONSTANTS.LOCATION_TARGETTING_ERROR_TEXT
              : PROPOSAL_CONSTANTS.MAPBOX_ERROR_TEXT
          }
        />
      );
    }

    if (this.state.mapBox === null) {
      return <div>Loading ...</div>;
    }

    const Map = ReactMapboxGl({
      accessToken: configData.mapBoxAccessToken,
      interactive: !this.props.disabled,
    });
    let zoom = [this.props.zoom];
    let newCoOrdinatesForMultiple = [];
    let markerPoints = [];
    let zipCenters = [];
    let geometryCoOrdinates = [];

    if (this.props.multipolygon && this.props.multipolygon.length > 0) {
      geometryCoOrdinates = this.props.multipolygon.map((data) => {
        return data.geometry;
      });
      //Method to get zipCenters
      geometryCoOrdinates.forEach((eachZip) => {
        if (eachZip) {
          zipCenters = eachZip.map((zip) => {
            if (zip) return zip.zipCodeCenterCoordinates;
            return undefined;
          });
        }
      });

      markerPoints = this.props.multipolygon.map((data) => {
        return data.locationCoordinates;
      });

      geometryCoOrdinates.map((data) => {
        if (data) {
          let eachRegionData = data.map((each) => {
            return each.zipCodeCoordinates;
          });
          eachRegionData.forEach((eachRegion) => newCoOrdinatesForMultiple.push([eachRegion]));
          return eachRegionData;
        }
        return undefined;
      });

      let zipCodes = _.get(this.props.multipolygon, "[0].zipCodes", 0);

      if (zipCodes.length === 1) {
        newCoOrdinatesForMultiple = newCoOrdinatesForMultiple[0];
      }
    } else {
      zoom = [PROPOSAL_CONSTANTS.ZOOM_INITIAL_VALUE];
    }

    let showBoundaries = false;

    if (
      newCoOrdinatesForMultiple &&
      newCoOrdinatesForMultiple[0] &&
      newCoOrdinatesForMultiple[0][0]
    ) {
      showBoundaries = true;
    }

    const markersList = markerPoints
      .filter((centerData) => centerData !== undefined)
      .map((centerData, idx) => (
        <Marker key={idx} coordinates={[centerData[1], centerData[0]]} anchor="center">
          <img src={pin} alt="marker" />
        </Marker>
      ));

    const zipMarkerList = zipCenters
      .filter((centerData) => centerData !== undefined)
      .map((centerData, idx) => (
        <Marker key={idx} coordinates={[centerData[1], centerData[0]]} anchor="bottom">
          <img src={pin} alt="marker" />
        </Marker>
      ));
    let centerCoOrdinates = [this.props.long, this.props.lat];
    if (markerPoints.length > 0) {
      let length = markerPoints.length;
      let centerPoint = markerPoints[length - 1];
      if (centerPoint) {
        centerCoOrdinates = [centerPoint[1], centerPoint[0]];
      }
    }
    let radiusMarker = [];
    if (this.props.multipolygon) {
      this.props.multipolygon.forEach((data) => {
        if (data.locationCoordinates) {
          radiusMarker.push(
            <Layer
              type="circle"
              paint={mapBoxHelper.getCirclePaint(data.radius, data.locationCoordinates[0])}
            >
              <Feature coordinates={[data.locationCoordinates[1], data.locationCoordinates[0]]} />
            </Layer>
          );
        }
      });
    }

    let fitBoundCoordinates = [
      [this.props.long, this.props.lat],
      [this.props.long, this.props.lat],
    ];
    let useFitBounds = false;
    if (
      this.props.zoom === PROPOSAL_CONSTANTS.ZOOM_DEFAULT_VALUE &&
      this.props.showGeoJsonLayer &&
      _.size(this.props.multipolygon) > 0
    ) {
      const features = this.props.multipolygon.reduce(
        (obj, boundary) => {
          const allCoordinateArrays = boundary.geometry;
          const coordinateLengths = allCoordinateArrays.map((a) => a.zipCodeCoordinates[0].length);
          const indexMaxCoords = coordinateLengths.indexOf(Math.max(...coordinateLengths));
          return {
            ...boundary,
            geometry: {
              type: boundary.geometry[indexMaxCoords].type,
              coordinates: boundary.geometry[indexMaxCoords].zipCodeCoordinates.concat(
                obj.geometry.coordinates
              ),
            },
          };
        },
        {
          geometry: {
            type: "",
            coordinates: [],
          },
        }
      );

      fitBoundCoordinates = this.getPolygonBoundingBox(features);
      if (_.size(fitBoundCoordinates[0])) {
        useFitBounds = true;
      }
    }

    const geoJsonCoordinates = _.cloneDeep(geometryCoOrdinates);

    return (
      <>
        <Map
          onZoomEnd={(_, ev) => {
            this.onZoomMap(ev);
          }}
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v8"
          zoom={zoom}
          containerStyle={{
            height: "295px",
          }}
          center={centerCoOrdinates}
          {...(useFitBounds && { fitBounds: fitBoundCoordinates })}
        >
          {!this.props.disabled && <ZoomControl position="bottom-right" />}

          {!this.props.isZipCodeSearch &&
            !this.props.showGeoJsonLayer &&
            markersList.map((eachPin) => eachPin)}
          {zipMarkerList.map((eachZip) => eachZip)}
          {!this.props.showGeoFenced && showBoundaries && !this.props.showGeoJsonLayer && (
            <Layer type="fill" paint={mapBoxHelper.polygonPaint}>
              <Feature coordinates={newCoOrdinatesForMultiple} />
            </Layer>
          )}

          {!this.props.showGeoFenced &&
            showBoundaries &&
            this.props.showGeoJsonLayer &&
            geoJsonCoordinates.map((geometry) => {
              return geometry.map((geometryItem, gIdx) => {
                geometryItem.coordinates = geometryItem.zipCodeCoordinates;

                return (
                  <GeoJSONLayer
                    key={gIdx}
                    data={geometryItem}
                    fillPaint={mapBoxHelper.polygonPaint}
                  />
                );
              });
            })}

          {this.props.showGeoFenced && radiusMarker}
        </Map>
      </>
    );
  }
}

MapBoxGl.defaultProps = {
  showGeoJsonLayer: false,
};

export default MapBoxGl;
