import { useState, useEffect } from "react";

import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

export default function useUserPreferences() {
  const [ret, setRet] = useState(undefined);
  useEffect(() => {
    async function getPreferences() {
      const reply = await request({
        url: `${URL.USER}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        json: true
      });
      setRet(reply.result);
    }

    getPreferences();
  }, [setRet]);

  return ret;
}
