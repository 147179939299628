import marketsAndOutletsService from "./marketsAndOutletsService";
import { API_STATUS } from "../../../property/constants/commonConstants";

export const LOAD_MARKETS_AND_OUTLETS = "LOAD_MARKETS_AND_OUTLETS";

export const loadMarketAndOutlets = () => {
  return async dispatch => {
    const response = await marketsAndOutletsService.loadMarketsAndOutlets();
    if (response.status === API_STATUS.SUCCESS) {
      dispatch({
        type: LOAD_MARKETS_AND_OUTLETS,
        payload: response.result.markets
      });
    }
  };
};
