import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import { loginFromMsp } from "./loginActions";

export default function TokenLogin() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.loginData.isLoggedIn);
  const location = useLocation();
  const { tenantId, token } = useParams();
  const params = queryString.parse(location.search);
  const { uri } = params;

  if (!isLoggedIn) {
    dispatch(loginFromMsp({ tenant_id: tenantId, token, uri }));
    return <div>Loading ...</div>;
  } else {
    return <Redirect to={uri} />;
  }
}
