import { LOAD_MARKETS_AND_OUTLETS } from "./marketsAndOutletsActions";

const initialState = [];

export const reducer = function (state, action) {
  if (!state) state = initialState;
  if (action.type === LOAD_MARKETS_AND_OUTLETS) {
    return action.payload;
  }
  return state;
};
