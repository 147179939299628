import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as LoginReducer } from "./components/login-page/loginReducer";
import {
  creativeList,
  order,
  updateLinesFrmInfo,
  uploadedCreatives,
} from "./components/commons/digital-lines/linesReducer";
import { reducer as orderLookupsReducer } from "./components/commons/order-lookups/orderLookupsReducer";
import { updateSelectedImageReducer } from "./reducers/updateSelectedImageReducer";
import { reducer as advertiserSearchReducer } from "./components/commons/advertiserSearch/advertiserSearchReducer";
import { reducer as lookupAdvertiserSearchReducer } from "./components/commons/advertiserSearch/lookupAdvertiserSearchReducer";
import { reducer as marketsAndOutletsReducer } from "./components/commons/markets-and-outlets/marketsAndOutletsReducer";
import { reducer as creativeDimensionsReducer } from "./components/commons/creative-dimensions/creativeDimensionsReducer";
import { reducer as metaReducer } from "./helpers/metaReducer";
import { reducer as UserPreferencesReducer } from "./components/commons/user-preferences/UserPreferencesReducer";
import { reducer as agencySearchReducer } from "./components/commons/agencySearch/agencySearchReducer";
import { reducer as billingAgencySearchReducer } from "./components/commons/agencySearch/billingAgencySearchReducer";
import { reducer as mspViewStateReducer } from "./components/commons/mediascape/mspViewStateReducer";

export default combineReducers({
  advertiserSearch: advertiserSearchReducer,
  lookupAdvertiserSearch: lookupAdvertiserSearchReducer,
  agencySearch: agencySearchReducer,
  billingAgencySearch: billingAgencySearchReducer,
  creativeList,
  form: formReducer,
  imageStore: updateSelectedImageReducer,
  linesFrm: updateLinesFrmInfo,
  loginData: LoginReducer,
  marketsAndOutlets: marketsAndOutletsReducer,
  creativeDimensions: creativeDimensionsReducer,
  metaReducer: metaReducer,
  order,
  orderLookups: orderLookupsReducer,
  uploadedCreatives,
  userPreferences: UserPreferencesReducer,
  mspViewState: mspViewStateReducer,
});
