import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import bg_oval from "../../assets/bg_oval.png";
import bg_art_network from "../../assets/bg1.png";
import "./login.scss";

export default function Logout() {
  const [preLogout, setPreLogout] = useState(true);
  useEffect(() => {
    async function logout() {
      try {
        await Auth.currentAuthenticatedUser();
        Auth.signOut();
      } catch (e) {
        if (e === "not authenticated") {
          setPreLogout(false);
        }
      }
    }

    logout();
  }, []);

  return (
    <div className="main-panel">
      <div className="loginForm">
        <div className="login-form-container">
          <Card className="login-card">
            <CardContent className="card-login-form">
              {preLogout && <div>Logging out...</div>}
              {!preLogout && (
                <div>
                  You are Logged out. You may log back in by clicking <a href="/">here</a>.
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="bg-layer">
        <div className="bg-art-network">
          <img src={bg_art_network} alt="Smiley face"></img>
        </div>
        <div className="bg-oval-bottom">
          <img src={bg_oval} alt="Smiley face"></img>
        </div>
      </div>
    </div>
  );
}
