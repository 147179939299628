import moment from "moment";
import advertiserImg from "./../../assets/simple-proposal/advertiser.svg";
import budgetDateImg from "./../../assets/simple-proposal/budgetsdates.svg";
import summaryImg from "./../../assets/simple-proposal/summary.svg";

export const PROPOSAL_CONSTANTS = {
  ZOOM_DEFAULT_VALUE: 8,
  ZOOM_INITIAL_VALUE: 4,
  SIMPLE_PROPOSAL: "SIMPLE_PROPOSAL",
  STANDARD_PROPOSAL: "STANDARD_PROPOSAL",
  TARGETING_SEARCH_TYPE: {
    PLACE: "place",
    POI: "poi",
    RADIUS: "radius",
    ADDRESS: "address",
    DISTRICT: "district"
  },
  DEFAULT_LAT_LONG: [37.0902, -95.7129],
  PROPOSAL_NAME_MAX_CHAR: 47,
  PROPOSAL_NAME_MIN_CHAR: 3,
  WEEKLY_DAYS: 7,
  MONTHLY_DAYS: 30,
  PROPOSAL_CURRENT_STATUS: "proposalCurrentStatus",
  PROPOSAL_TARGET_BUDGET: "grandTotal",
  MAPBOX_ERROR_TEXT:
    "NXT has encountered a problem with the location mapping service and is temporarily unable to show the map.",
  LOCATION_TARGETTING_ERROR_TEXT:
    "NXT has encountered a problem with the location targeting service. Please try again in a little while. If the problem persists, please contact your support representative for help.",
  NO_TARGETING_TEXT:
    "The Targeting step is not needed when only Airtime is selected as the Ad Type. Please click NEXT to continue.",
  SPECIAL_CHARACTER: {
    SEMICOLON: ":",
    SPACE: " "
  },
  PROPOSAL_HEADING: "Proposal",
  PROPOSAL_BUILDER: "Proposal Builder",
  MEDIA_TYPE_CODE: {
    RADIO: "tdo",
    TARGETED_DISPLAY: "tv",
    TARGETED_VIDEO: "vdo",
    CONNECTED_TV: "ctv",
    GEO_FENCED_DISPLAY: "gfv",
    GEO_FENCED_VIDEO: "gfd"
  },
  MEDIA_TYPE_ID: {
    TARGETED_DISPLAY: 1,
    TARGETED_VIDEO: 2,
    GEO_FENCED_DISPLAY: 3,
    GEO_FENCED_VIDEO: 4,
    RADIO: 5,
    CONNECTED_TV: 6
  },
  ADMIN_FEE_TYPE: {
    MONTHLY: "monthly",
    MONTHLY_FEE_TITLE: "Monthly Fee",
    STATION_FEE: "stationfee",
    STATION_FEE_TITLE: "Station Fee"
  },

  INITIAL_MONTHLY_FEE: {
    budget: 0,
    adminFeeType: "monthly",
    budgetTitle: "Monthly Fee"
  },

  INITIAL_STATION_FEE: [
    {
      budget: 0,
      adminFeeType: "stationfee",
      budgetTitle: "Station Fee"
    }
  ],

  BUDGET_TYPE: {
    BUDGET: "budget",
    TARGETED_IMPRESSION: "impression"
  },
  AUDIENCE_INTEREST: {
    CATEGORY_SELECTED_LABEL: "categories Selected",
    INTEREST_TARGETING_LABEL: "Interest Targeting",
    INTERSESTS_LABEL: "Interests"
  },
  PROPOSAL_OUTPUT: {
    AUDIENCE_LABEL: "Audience",
    TARGETING_HEADING: "Demographic and Interest Targeting",
    DEMOGRAPHY_TARGETING_HEADING: "Demographic Targeting",
    GENDER_LABEL: "Gender",
    AGE_LABEL: "Age",
    HH_INCOME_LABEL: "Household Annual Income",
    PARENTING_LABEL: "Parenting",
    EDUCATION_LABEL: "Education",
    TARGETED_AUDIENCE_SIZE: "Audience Targeted Ads Target Audience size",
    TARGETED_AUDIENCE_SIZE_OTT: "OTT Ads Target Audience size",
    INTEREST_TARGETING_LABEL: "Interest Targeting:",
    LOCATION_TARGETING_HEADING: "Location Targeting",
    LOCATION_AUDIENCE_ADS: "Location for Audience-Targeted-Ads",
    ADVERTISER_LABEL: "Advertiser:",
    PROPOSAL_CREATED_BY: "Proposal Created By:",
    IMAGE: "image"
  },
  COMMONS: {
    ASC: "ASC",
    ACTION: "Action",
    DESC: "DESC",
    NEXT: "Next >>",
    CLOSE: "Close",
    PREVIOUS: "<< Previous",
    SUBMIT: "Submit",
    SAVE_AS_DRAFT: "Save as Draft",
    CLEAR_ALL: "Clear All",
    GENERATE: "Save",
    GENERATEANDPREVIEW: "Save & Preview >>"
  },
  DATE_FORMAT: {
    YYYY_MM_DD: "YYYY-MM-DD",
    MM_DD_YYYY: "MM/DD/YYYY",
    PICKER: "MM/dd/yyyy"
  },
  FUTURE_DATE: {
    CURRENT_DATE_PLUS_ONE: moment()
      .add(1, "days")
      .format("MM/DD/YYYY"),
    CURRENT_DATE_PLUS_EIGHT: moment()
      .add(8, "days")
      .format("MM/DD/YYYY"),
    CURRENT_DATE: moment().format("MM/DD/YYYY")
  },

  NEW_DATE: {
    CURRENT_DATE_PLUS_ONE: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
    CURRENT_DATE_PLUS_EIGHT: new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000)
  },
  PROPOSAL_VIEW_MAX_STEP: 6,
  PROPOSAL_VIEW_INITIAL_STEP: 1,
  PROPOSAL_VIEW_STEP_INC_DEC_BY: 1,
  DATE_VALUE_IN: {
    DAY: "days"
  },

  API_STATUS: {
    SUCCESS: "success",
    FAIL: "fail"
  },

  PROPOSAL_IS_ACTIVE: {
    DELETED: 0,
    ACTIVE: 1
  },

  PROPOSAL_VIEW_LEVELS: {
    ACTION: "Actions",
    ADVERTISER_NAME: "Advertiser Name",
    AGREEMENT_FOR: "Agreement for",
    BUDGET: "Budget",
    CLIENT_NAME: "Client Name",
    CLIENT_SIGNATURE: "Client Signature",
    COMMERCIAL_TYPE: "Commercial Type",
    DIGITAL_TARGETING: "Digital Targeting",
    DURATION: "Duration",
    EST_DIGITAL_REACH: "Estimated Digital Reach Percentage",
    EST_DIGITAL_FREQUENCY: "Estimated Digital Frequency",
    FREQUENCY: "Frequency",
    MEDIATYPE: "Media Type",
    OWNER: "Salesperson",
    AUTHOR: "Author",
    PROPOSAL_NAME: "Proposal Name",
    PROPOSAL_SUMMARY: "Proposal Summary",
    RADIO: "Radio",
    REACH_FREQUENCY: "Reach / Frequency",
    REACH: "Reach",
    REQUESTED_DATE: "Requested Dates",
    PROPOSAL_DATE: "Proposal Date",
    SALES_PERSON: "Salesperson",
    STATION: "Station",
    STATUS: "Status",
    TARGET_BUDGET: "Target Budget",
    TOTAL_DIGITAL_BUDGET: "Total Digital Budget",
    TOTAL_BUDGET: "Total Budget",
    TOTAL_GRAND: " Total Grand",
    TOTAL_RADIO_BUDGET: "Total Radio Budget",
    UPDATED_ON: "Updated Date",
    CREATED_ON: "Creation Date",
    ESTIMATED_DIGITAL_REACH: "Estimated Digital Reach Percentage",
    ESTIMATED_DIGITAL_FREQUENCY: "Estimated Digital Frequency"
  },

  PROPOSAL_FIELDS: {
    PROPOSAL_NAME: "proposalName",
    ADVERTISER_NAME: "advertiserName",
    PROPOSAL_UPDATE_DATE: "proposalUpdatedDate",
    PROPOSAL_CREATED_DATE: "proposalCreatedDate",
    GRAND_TOTAL: "grandTotal",
    OWNER_NAME: "ownerName",
    AUTHOR_NAME: "createdByName",
    PROPOSAL_CURRENT_STATUS: "proposalCurrentStatus"
  },

  PROPOSAL_STAGES: {
    DRAFT: "Draft",
    NEW: "New",
    COMPLETE: "Complete",
    ARCHIVED: "Archived",
    ORDER_GENERATED: "Order Generated"
  },

  CREATE_PROPOSAL_STEPS: {
    PROPOSAL: 0,
    ADS: 1,
    TARGETING: 2,
    BUDGET: 3,
    DEC_BY: 1,
    INC_BY: 1
  },

  PAGES: {
    CREATE_PROPOSAL_PAGES: [
      {
        stepLabel: "Proposal"
      },
      {
        stepLabel: "Ads"
      },
      {
        stepLabel: "Targeting"
      },
      {
        stepLabel: "Budget"
      }
    ]
  },
  MEDIA_DETAILS: {
    MEDIA_LABEL: "Media",
    SELECT_MEDIA_TEXT: "Select Media Types",
    SELECT_MEDIA_TYPES: "Select Ad Type",
    RADIO_SCHEDULE: "Airtime Schedule",
    SCHEDULE_UPLOAD: "Upload XML",
    SCHEDULE_MANUALLY: "Input Manually",
    ADD_LATER: "Create Airtime Order Lines later",
    FLIGHT_TEXT: "Flight",
    XML_UPLOAD: {
      DEMOCATEGORES: "demoCategories",
      TOTAL_COSTS: "totalCosts",
      TOTAL_SPOTS: "totalSpots",
      SCHEDULE_TYPE: "uploadxml",
      MEDIA_SCHEDULE_ID: "proposalMediaScheduleId",
      FILE: "file",
      MARKET_ID: "marketId"
    }
  },

  MEDIA_SCHEDULE_INPUT_TYPE: {
    CHECK_UPLOAD_XML: "uploadxml",
    UPLOAD_XML: "uploadxml",
    MANUAL_INPUT: "manualinput",
    ADD_LATER: "uploadlater"
  },

  MANUAL_INPUT_SPOTS_LENGTH: [10, 15, 30, 45, 60],
  MAX_MANUAL_INPUT_STATION: 10,
  MAX_OUTLET_SCHEDULE: 10,
  MANUAL_INPUT_MIN_SPOT_VALUE: 1,
  MANUAL_INPUT_MAX_SPOT_VALUE: 2500,
  MANUAL_INPUT_DAY_PART: [
    {
      id: 1,
      name: "Morning Drive",
      startTime: "06:00:00 AM",
      endTime: "10:00:00 AM"
    },
    {
      id: 2,
      name: "Mid-Day",
      startTime: "10:00:00 AM",
      endTime: "03:00:00 PM"
    },
    {
      id: 3,
      name: "Afternoon Drive",
      startTime: "03:00:00 PM",
      endTime: "07:00:00 PM"
    },
    {
      id: 4,
      name: "Evening",
      startTime: "07:00:00 PM",
      endTime: "12:00:00 AM"
    },
    {
      id: 5,
      name: "Overnight",
      startTime: "12:00:00 AM",
      endTime: "06:00:00 AM"
    },
    {
      id: 6,
      name: "Run of Schedule",
      startTime: "12:00:00 AM",
      endTime: "12:00:00 AM"
    },
    {
      id: 7,
      name: "Custom"
    }
  ],
  MANUAL_INPUT_DISTRIBUTION_TYPE: ["WEEKLY"],
  MANUAL_INPUT_DISTRIBUTION_DEFAULT: "WEEKLY",
  OUTLET_DETAILS: {
    IS_ACTIVE: 1,
    MANUAL_INPUT_MIN_OUTLET: 1,
    MANUAL_INPUT_MAX_OUTLET: 10,
    MANUAL_INPUT_MIN_OUTLET_SCHEDULE: 1,
    MANUAL_INPUT_MAX_OUTLET_SCHEDULE: 10
  },
  PROPOSAL_BUDGET: {
    BUDGET_TITLE: "Budget",
    SELECTED_MEDIA: "Selected Media",
    AD_TYPES: "Ad Types",
    CPM: "CPM",
    DESIRED_SHARE: "Desired Shares",
    DIGITAL_SUB_TOTAL: "Digital Sub Total",
    GRAND_TOTAL: "Grand Total",
    TOTAL_BUDGET: "Total Budget",
    TARGET_ALLOCATION: "Target Allocation",
    RECOMMENDED: "Recommended",
    ADVERTISER: "Advertiser",
    IMPRESSION: "Impression",
    EST_BUDGET: "Est. Budget",
    START_DATE: "Required",
    END_DATE: "Required",
    DATE_REQUIRED: "Required",
    DATE_PAST: "Cannot be in the past",
    DATE_INVALID: "Invalid",
    END_DATE_MIN: "The minimum length is 7 days",
    TOTAL_DIGITAL_BUDGET: "Required",
    BUDGET_GRAND_TOTAL: "*Grand total should be greater than 0"
  },
  DATES: {
    START_DATE: "Start Date",
    END_DATE: "End Date"
  },
  LOCATION_TARGETING: {
    LOCATION_TARGETING: "Location Targeting",
    LOCATION_10_MILES:
      "By default, zip codes within 10 miles of the advertiser's address have been selected. If you wish to select a different geography please toggle the option below and choose a region, zip codes, or radius around an address.",
    LOCATION_10_MILES_NOT_DEFAULT: "Choose a region, zip codes, or radius around an address.",
    POSTCODE: "postcode",
    GEOGRAPHY_TYPE: "geographyType",
    TAB_AUDIENCE_TITLE: "Audience Targeted Ads",
    TAB_GEOFENCE_TITLE: "Geofenced Targeted Ads",
    TAB_OTT_TITLE: "OTT",
    TAB_GEOFENCE_NOW: "Enter Geofence Now",
    TAB_GEOFENCE_LATER: "Enter Geofence Later",
    INVALID_ZIPCODES:
      "The advertiser address contains an invalid zip code. You may enter a location in the geography type fields below. Alternately you can correct the zip code in the advertiser account.",
    ZIP_CODE_ERROR_MESSAGE: "Invalid zip codes detected. Please enter valid 5-digit zip codes."
  },
  GEO_FENCE_TARGETING: {
    MILES: "miles",
    GEO_FENCE_DEFAULT:
      "By default, a geofence 1 mile around advertiser's address has been selected. If you wish to select a different geography please toggle the option below and input geofences to target.",
    GEO_FENCE_NOT_DEFAULT: "Choose an address.",
    GEO_FENCE_1_MILES: "1 mile around advertiser address."
  },
  OTT_TARGETING: {
    LOCATION_DEFAULT:
      "By default, the state from the Advertiser's address has been selected. If you wish to select a different geography please toggle the option below and choose a DMA or State to target.",
    LOCATION_NOT_DEFAULT: "Choose a DMA, State, or Zip codes.",
    INVALID_ZIPCODES:
      "The advertiser address contains an invalid state. You may enter a location in the geography type fields below. Alternately you can correct the state in the advertiser account.",
    MIN_10_ZIP_CODES_ERROR: "Minimum 10 zip codes are required."
  },
  LANDINGPAGE_LABELS: {
    PROPOSAL: "Proposal",
    PROPOSAL_URI: "/proposals",
    CREACTIVE_MANAGEMENT: "Creative Management",
    ORDERS: "Orders",
    ORDERS_URI: "/orders",
    CAMPAIGNS: "Campaigns",
    CAMPAIGNS_URI: "/campaigns",
    FEEDBACK: "Provide Feedback",
    ANALYTICS: "Analytics"
  },
  QUERY_PARAMETER: {
    LIMIT: "limit",
    SEARCH: "search",
    FILTERS: "filters",
    SORT_BY: "sortBy",
    SORT_ORDER: "sortOrder",
    OFFSET: "offset",
    DEFAULT_ORDER_BY_FIELD: {
      PROPOSAL: {
        FIELD: "proposalUpdatedDate",
        ORDER: "desc"
      },
      ORDER: {
        FIELD: "orderUpdatedDate",
        ORDER: "desc"
      }
    }
  },
  PROPOSALS_TABLE_COLUMN: {
    AUTHOR_NAME: "authorName",
    OWNER_NAME: "ownerName",
    ADVERTISER_CONTACT_NAME: "advertiserContactName",
    INDUSTRY_NAME: "industryName",
    PROPOSAL_LOCATION_ID: "proposalLocationId"
  },
  PROPOSAL_STATUS: {
    DRAFT: "draft",
    SUBMIT: "submit",
    MODE: "standard",
    PROPOSAL_STEP: "proposal",
    MEDIA_STEP: "media",
    TARGETING_STEP: "targeting",
    BUDGET_STEP: "budget",
    COMPLETE: "Complete",
    NEW: "New",
    ORDER_GENERATED: "Order Generated"
  },
  DEMOGRAPHIC_LEVEL: {
    AUDIENCE: "Audience",
    DEMOGRAPHY_TARGETING: "Demographic Targeting",
    DESELECT_ALL: "Deselect All"
  },

  PROPOSAL_REQUEST_BODY: {
    PROPOSAL: "proposal",
    ADVERTISER: "advertiser"
  },
  PROPOSAL_LIST_MENU: {
    ARCHIVE: "Archive",
    UNARCHIVE: "UnArchive",
    COPY: "Copy",
    VIEW_ORDER: "View/Edit the Order",
    GENERATE_ORDER: "Generate Order",
    PROPOSAL_OUTPUT: "Preview",
    EDIT: "Edit",
    PUBLISH: "Publish",
    PPT: "Download as PowerPoint",
    PDF: "Download as PDF"
  },

  PROPOSAL_CREATION_STEPS: {
    FIRST: 1,
    SECOND: 2,
    THIRD: 3,
    FOURTH: 4,
    FIFTH: 5
  },
  REACH_FREQUENCY_DISCLAIMERS: {
    FIRST: `Estimated reach is based on U.S. Census community data for the selected zip code,
demographics and other factors`,
    SECOND: `A frequency of between 3 to 5 is recommended to attain effective digital penetration`
  },
  PROPOSAL_STEP1: {
    UPLOAD_XML_ERROR: "Required, File cannot be empty.",
    proposalName: {
      ERROR_TEXT:
        "The Proposal Name must be 3 to 47 characters long and without special characters ' + * - " +
        '"'
    },
    opportunityOwnerId: {
      ERROR_TEXT: "Required"
    },
    presentationDate: {
      ERROR_REQUIRED: "Required",
      ERROR_INVALID: "Must be in the future",
      ERROR_PAST: "Date is not valid"
    },
    marketId: {
      ERROR_TEXT: "Required"
    },
    outletId: {
      ERROR_TEXT: "Required"
    },
    advertiserId: {
      ERROR_TEXT: "Required"
    },
    billingAccountId: {
      ERROR_TEXT: "Account is required"
    },
    industryId: {
      ERROR_TEXT: "Required",
      NAME: "industryId"
    },
    agencyId: {
      ERROR_TEXT: "Required"
    },
    marketAgencyId: {
      ERROR_TEXT: "Required"
    },
    advertiserAccountId: {
      ERROR_TEXT: "Required"
    }
  },
  CREATIVE: {
    ADVERTISER_ID: "advertiserId",
    MARKET_ID: "marketId",
    MEDIA_TYPE_ID: "mediaTypeId",
    CREATIVE_TYPE: "creativeType",
    IS_CONFIRMED: "isConfirmed"
  }
};

export const SIMPLE_PROPOSAL_CONSTANTS = {
  creationStage: {
    basic: "basic",
    budgets: "budgets",
    summary: "summary"
  },
  mediaCodeById: {
    1: "tv",
    2: "vdo",
    3: "gfd",
    4: "gfv",
    5: "tdo",
    6: "ctv"
  },
  mediaNameById: {
    1: "Display",
    2: "Video",
    3: "Geofence",
    4: "Video Geofence",
    5: "Airtime",
    6: "OTT"
  },

  BRAND_AWARENESS_PLUS_HAS_VIDEO_CREATIVES: [1, 2, 3, 4, 5, 6],
  BRAND_AWARENESS_PLUS_NO_VIDEO_CREATIVES: [1, 3, 4, 5, 6],
  VISITS_PLUS_HAS_VIDEO_CREATIVES: [1, 2, 5, 6],
  VISITS_PLUS_NO_VIDEO_CREATIVES: [1, 5, 6],

  AD_TYPE_NAME: {
    VIDEO: "Video",
    AIRTIME: "Airtime",
    ONE_TIME_FEE: "Station Fee"
  },

  BASIC_INFO_FIELD_NAMES: {
    proposalName: "proposalName"
  },

  SUMMARY_TABLE_HEADINGS: {
    AD_TYPES: "Ad Types",
    TARGETING: "Targeting",
    INVESTMENT: "Investment"
  },

  SUMMARY_TABLE_DEVICE_TYPES: {
    DISPLAY_VIDEO: "Mobile, tablet & Computers",
    AIRTIME: "",
    GEOFENCE_VIDEO: "Mobile Devices & Tablets",
    OTT: "CTV, Desktop, Mobile"
  },

  SUMMARY_TABLE_DESCRIPTION: {
    DISPLAY: "Ads will be targeted by audience(demographic, interest, and location)",
    VIDEO_GEO:
      "Ads will be targeted to a specific radius around locations based on the physical locations of mobile devices",
    GEOFENCE_VIDEO: "Ads will be targeted based on the viewers proximity to a specific location.",
    OTT:
      "Ads will be targeted to high quality video content across multiple devices including Connected TV devices"
  },

  SUMMARY_CONSTANTS: {
    REVIEW_TEXT:
      "  Please review the budgets and media types that have been selected. You may modify these options by going back to the Advertiser or Budgets & Dates sections"
  },

  ENABLE_AIRTIME: "Enable Airtime",
  DISABLE_AIRTIME: "Disable Airtime",
  BASED_ON_GOALS:
    "Enter the total budget you expect to spend on digital advertising for the duration of the specified date range. Based on your goal selection in the previous step, and your Total Digital Budget, NXT will select appropriate ad types and allocate your budget for an effective campaign.",
  MARKETING_GOAL: "Marketing Goal",
  MARKETING_GOALS_OPTIONS: {
    BRAND_AWARENESS: {
      TITLE: "Brand Awareness",
      DESCRIPTION: "Build awareness of your business in the local area"
    },

    VISITS: {
      TITLE: "Visits",
      DESCRIPTION: "Drive visits to your websites or your local stores"
    },

    SELECTED_GOALS: "Selected Goals"
  },

  PAGES: {
    SIMPLE_PROPOSAL_PAGES: [
      {
        title: "Advertiser",
        image: advertiserImg,
        stepLabel: "Basic Info",
        shortDesc: "Add advertiser details  and select goals"
      },
      {
        title: "Budgets & Dates",
        image: budgetDateImg,
        stepLabel: "Budgets & Dates",
        shortDesc: "Set your dates and budget with ease",
        infoSection: ""
      },
      {
        title: "Summary",
        image: summaryImg,
        stepLabel: "Summary",
        shortDesc: "A preview of all your selections"
      }
    ]
  },

  SIMPLE_PROPOSAL_STEPS: {
    BASIC_INFO: 0,
    BUDGETS_AND_DATES: 1,
    SUMMARY: 2
  },

  ERRORS: {
    REQUIRED: "Required",
    REQUIRED_AD_TYPE: "*Required. Select at least 1 Ad Type",
    MINIMUM_CAMPAIGN_RANGE: "The minimum length is 7 days",
    proposalNameError: "proposalNameError",
    goalsNotSelected: "goalsNotSelected",
    tentativeStartDateNull: "tentativeStartDateNull",
    tentativeEndDateNull: "tentativeEndDateNull",
    mediaScheduleNull: "mediaScheduleNull",
    totalDigitalBudgetNull: "totalDigitalBudgetNull",
    adTypeRequired: "adTypeRequired",
    NoMediaType: "NoMediaType",
    campaignLesserThan7Days: "campaignLesserThan7Days"
  },

  TOGGLE_TEXT_PART_ONE: "Does the advertiser have the ability to",
  TOGGLE_TEXT_PART_TWO: "produce video creative files?",
  NON_DIRECT_BUSINESS_DIALOG: {
    TEXT1: "The guided proposal cannot be used for Non-Direct business.",
    TEXT2: "Please use the customizable proposal instead."
  }
};

export const PROPOSAL_CREATION_MODE = {
  SIMPLE: "supersimplified",
  BASIC: "basic"
};

export const INPUT_ATTRIBUTE = {
  MEDIA_TYPE: {
    MEDIA_TYPE_ID: "*Required. Select at least 1 Ad Type",
    MEDIA_SCHEDULE: "*Required"
  },

  TARGETING: {
    LOCATION_TARGETING: "*Required. Select at least 1 location",
    ZIPCODE_ERROR: "'zipCodes' - must contain at least 1 items",
    AUDIENCE_INTEREST: "*Please select at least 3 audience interests",
    RADIUS_ERROR: "*Radius is required"
  },
  RADIO_MANUAL_INPUT_ATTRIBUTE: {
    outletName: {
      LABEL: "Station",
      NAME: "outletName",
      ERROR_TEXT: "Required"
    },
    commercialType: {
      LABEL: "Commercial Type",
      NAME: "commercialType",
      ERROR_TEXT: "Required"
    },
    spots: {
      LABEL: "Spots Per Week",
      NAME: "spotsPerWeek",
      ERROR_TEXT: "Required",
      VALID_NUMBER: "Required Please enter positive number"
    },
    length: {
      LABEL: "Length",
      NAME: "spotLength",
      ERROR_TEXT: "Required"
    },
    rate: {
      LABEL: "Rate",
      NAME: "rate",
      ERROR_TEXT: "Required",
      VALID_NUMBER: "Please enter a Integer with no decimals or decimal with up to 2 spaces"
    },
    dayPartId: {
      LABEL: "Day Part",
      NAME: "dayPartId",
      ERROR_TEXT: "Required"
    },
    dayPartName: {
      NAME: "dayPartName"
    },
    campaignName: {
      LABEL: "Campaign Name",
      NAME: "campaignName",
      ERROR_TEXT: "Required"
    },
    outletId: {
      LABEL: "Outlet",
      NAME: "outletId",
      ERROR_TEXT: "Required"
    },
    inventoryTypeId: {
      LABEL: "Inventory Type",
      NAME: "inventoryTypeId",
      ERROR_TEXT: "Required"
    },
    distributionType: {
      LABEL: "Distribution Type",
      NAME: "patternType",
      ERROR_TEXT: "Required"
    },
    startDate: {
      LABEL: "Start Date",
      NAME: "startDate",
      ERROR_TEXT: "Required",
      REQUIRED: true
    },
    endDate: {
      LABEL: "End Date",
      NAME: "endDate",
      ERROR_TEXT: "Required",
      REQUIRED: true
    },
    deliveryStartTime: {
      LABEL: "Delivery Start Time",
      NAME: "deliveryStartTime"
    },
    deliveryEndTime: {
      LABEL: "Delivery End Time",
      NAME: "deliveryEndTime"
    }
  },

  BUDGET: {
    IMPRESSION: {
      LABEL: "Target Impression",
      NAME: "impression"
    },
    BUDGET: {
      LABEL: "Total Digital Budget",
      NAME: "budget"
    },
    TENTATIVE_START_DATE: {
      LABEL: "Start Date",
      NAME: "tentativeStartDate"
    },
    TENTATIVE_END_DATE: {
      LABEL: "End Date",
      NAME: "tentativeEndDate"
    }
  }
};

export const ERROR_TEXT = {
  START_DATE: "Start date must be greater than current date",
  END_DATE: "End Date must be greater than start date",
  TARGETED_IMPRESSION: "Targeted impression must be greater than 0",
  DIGITAL_BUDGET: "Digital budget must be greater than 0",
  MEDIA_BUDGET: "Selected media budget must be greater than 0",
  PROPOSAL_NAME:
    "The Proposal Name must be 3 to 47 characters long and without special characters ' + * -"
};

export const MANUAL_AIRTIME = {
  SAVE_SUCCESS_TITLE: "Saved!",
  SAVE_SUCCESS_CONTENT: "Airtime schedule details<br /> has been saved successfully"
};

export const PROPOSAL_TABLE_CONFIG = {
  ROW_HEIGHT: 56
};

export const PROPOSAL_DIALOG = {
  SIMPLE_PROPOSAL_OPTIONS: 0,
  STANDARD_PROPOSAL_OPTIONS: 1,
  CREATE_PROPOSAL_LABEL: "Create Proposal",
  PICK_PROPOSAL_TEXT: " Pick your proposal creation mode",
  SIMPLE_SELECT_TITLE: "Simple",
  SIMPLE_SELECT_DESC: "Let us do the work. We'll make some selections based on the campaign goal.",
  STANDARD_SELECT_TITLE: "Targeted",
  STANDARD_SELECT_DESC: "You take control. You’ll choose and select all settings.",
  DEFAULT_SELECT_TEXT_1: "Select this checkbox to set the selected option as your default mode.",
  PREFERENCE_RESET_OPTION_TEXT:
    "You can reset this option under Preferences if you change your mind later.",
  CONTINUE: "Continue"
};

export const SIMPLE_GOAL_BRAND_MEDIA_TYPES = [
  {
    mediaTypeId: 1,
    mediaTypeName: "Display",
    mediaTypeCode: "tv",
    enable: true
  },
  {
    mediaTypeId: 2,
    mediaTypeName: "Video",
    mediaTypeCode: "vdo",
    enable: true
  },
  {
    mediaTypeId: 3,
    mediaTypeName: "Geofence",
    mediaTypeCode: "gfd",
    enable: true
  },
  {
    mediaTypeId: 4,
    mediaTypeName: "Video Geofence",
    mediaTypeCode: "gfv",
    enable: false
  },
  {
    mediaTypeId: 6,
    mediaTypeName: "OTT",
    mediaTypeCode: "ctv",
    enable: false
  }
];

export const SIMPLE_GOAL_VISIT_MEDIA_TYPES = [
  {
    mediaTypeId: 1,
    mediaTypeName: "Display",
    mediaTypeCode: "tv",
    enable: true
  },
  {
    mediaTypeId: 2,
    mediaTypeName: "Video",
    mediaTypeCode: "vdo",
    enable: true
  },
  {
    mediaTypeId: 3,
    mediaTypeName: "Geofence",
    mediaTypeCode: "gfd",
    enable: false
  },
  {
    mediaTypeId: 4,
    mediaTypeName: "Video Geofence",
    mediaTypeCode: "gfv",
    enable: false
  },
  {
    mediaTypeId: 6,
    mediaTypeName: "OTT",
    mediaTypeCode: "ctv",
    enable: false
  }
];

export const SIMPLE_PROPOSAL_GOALS = {
  BRAND_AWARENESS: 1,
  VISIT: 2,
  BRAND_AWARENESS_NO_VIDEO: 3,
  VISIT_NO_VIDEO: 4,
  BRAND_AWARENESS_STRING: "brand",
  VISIT_STRING: "visit"
};
