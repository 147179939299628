import React, { Component } from "react";
import { datadogLogs } from "@datadog/browser-logs";

import ErrorOverLay from "../error-overlay/ErrorOverLay";
import { ERROR_CONSTANTS } from "../../../property/constants/errorConstants";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: null };
  }

  componentDidCatch(error, info) {
    console.log("ErrorBoundary: error & info ", error, info);
    datadogLogs.logger.error(error);
    this.setState({ hasError: true, info: error.toString() });
  }

  componentWillUnmount() {
    this.setState({ hasError: false, info: null });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorOverLay
          title={ERROR_CONSTANTS.errorBoundary.title}
          subTitle={ERROR_CONSTANTS.errorBoundary.subTitle}
          info={this.state.info}
        />
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
