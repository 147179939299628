import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

function loadCreativeDimensions() {
  let dimensionUrl = URL.LIST_CREATIVE_DIMENSIONS + "?dspId=1&creativeType=image,video";
  return request({
    url: dimensionUrl,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    json: true
  });
}

const creativeDimensionsService = {
  loadCreativeDimensions
};

export default creativeDimensionsService;
