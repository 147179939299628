import React from "react";
import map_disabled from "../../assets/map_disabled.svg";
import location_error from "../../assets/location_error.svg";
export function MapBoxFailure(props) {
  return (
    <div className={"map-failure-container"} style={{ backgroundImage: `url(${map_disabled})` }}>
      <div className={"location-error"}>
        <img src={location_error} alt={"Map Box Failure"}></img>
      </div>
      <h3 className={"map-error-message"}>
        <strong>Error while loading map.</strong>
      </h3>
      <div className={"map-error-message"}>{props.message}</div>
    </div>
  );
}
