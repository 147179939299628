import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";

function loadMarketsAndOutlets() {
  return request({
    url: URL.LIST_MARKETS,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    json: true
  });
}

const marketsAndOutletsService = {
  loadMarketsAndOutlets
};

export default marketsAndOutletsService;
