import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UserLogo from "../../assets/user.png";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

function UserProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const user = useSelector(state => state.loginData.user);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleSignOut() {
    //TODO: integrate with the logout api .
    history.push("/logout");
  }

  return (
    <div>
      <Button
        className="user-profile"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={UserLogo} alt="Marketron Logo" width="20" height="20"></img>
        <span className="text-size">Hi! {user.first_name}</span>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <span className="text-size">Profile</span>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <span className="text-size">Sign Out</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserProfile;
