import { notificationConstants } from "./notificationConstants";
export const notificationFormat = {
  type: notificationConstants.TYPE.WARNING,
  flag: false,
  position: {
    topBottom: notificationConstants.TOP_BOTTOM,
    leftRight: notificationConstants.LEFT_RIGHT
  },
  message: notificationConstants.MESSAGE
};
