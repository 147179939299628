import { Auth } from "aws-amplify";

import request from "../../utils/request";
import URL from "../../api-config/URLConfig";

async function loginUser({ tenant_name, userName }) {
  const currentSession = await Auth.currentSession();
  const token = currentSession.idToken.jwtToken;
  return request({
    url: URL.LOGIN_URL,
    method: "POST",
    data: {
      tenant_name,
      userName
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    json: true
  });
}

function validateToken(token) {
  return request({
    url: URL.VALIDATE_TOKEN,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    json: true
  });
}

const loginService = {
  validateToken,
  loginUser
};

export default loginService;
