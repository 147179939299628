export const config = {
  serverURL: "https://qa2.digital-api.mktnxt.com/api/v1.0",
  NXTServerURL: "https://{tenant}.qa2.mktnxt.com",
  gaTrackingId: "UA-39909867-5",
  gaDebug: false,
  environment: "qa2",
  clientId: "9ep27lregumtmqbhrlntir7gf",
  cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
  identityProvider: "marketron-dev.com",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
  uploadCreativeServerUrl: "https://digital-backend-qa2.mktnxt.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_s6bRlZd0d",
};
