import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./landingPage.scss";
import landingPageStyles from "../landing-page/LandingStyles.js";
import { connect } from "react-redux";
import LandingPageDrawer from "../landing-page/LandingPageDrawer";
import LandingPageToolBar from "../landing-page/LandingPageToolBar";
import LandingPageContent from "../landing-page/LandingPageContent";
import SnackBars from "../commons/app-notification/SnackBars";
import { notificationFormat } from "../commons/app-notification/notificationFormat";
import { withRouter } from "react-router";

const styles = landingPageStyles();
class Shell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {},
      loading: true,
      showIndustries: false,
      showProposalsList: true,
      showSearchBar: false,
      showBasicProposal: false,
      navigationStepper: false,
      showMediaTypCreation: false,
      orderList: false,
      showEditOrder: false,
      campaign: false,
      showGenerateOrder: false,
      notification: notificationFormat
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  showProposals = () => {
    this.setState({
      showProposalsList: true,
      orderList: false,
      viewProposal: false,
      showEditOrder: false,
      campaign: false,
      showBasicProposal: false
    });
  };
  showOrders = () => {
    this.setState({
      showProposalsList: false,
      viewProposal: false,
      orderList: true,
      campaign: false,
      showEditOrder: false
    });
  };

  showCampaigns = () => {
    this.setState({
      campaign: true,
      viewProposal: false,
      showProposalsList: false,
      orderList: false,
      showEditOrder: false
    });
  };

  handleCloseNotification = () => {
    this.setState({
      notification: notificationFormat
    });
  };

  render() {
    const { classes, children, isStandalone } = this.props;
    let activelink = "";

    // To add image for collapsible menu
    let menuClassName = "";
    if (this.state.open) {
      menuClassName = "menu-background-img";
    } else {
      menuClassName = "menu-background-white";
    }

    if (isStandalone) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <LandingPageToolBar
            classes={this.props.classes}
            theme={this.props.classes}
            open={this.state.open}
            handleDrawerOpen={this.handleDrawerOpen}
          />
          <LandingPageDrawer
            open={this.state.open}
            classes={this.props.classes}
            handleDrawerClose={this.handleDrawerClose}
            menuClassName={menuClassName}
            showProposals={this.showProposals}
            showOrders={this.showOrders}
            showCampaigns={this.showCampaigns}
            theme={this.props.classes}
            activelink={activelink}
          />
          <LandingPageContent classes={this.props.classes}>
            {children}
            {this.state.notification.flag && (
              <SnackBars
                notificationData={this.state.notification}
                handleCloseNotification={this.handleCloseNotification}
              />
            )}
          </LandingPageContent>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <LandingPageContent classes={this.props.classes}>
            {children}
            {this.state.notification.flag && (
              <SnackBars
                notificationData={this.state.notification}
                handleCloseNotification={this.handleCloseNotification}
              />
            )}
          </LandingPageContent>
        </div>
      );
    }
  }
}

Shell.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  updateProposalStateDataStatus: PropTypes.func,
  orderDataDetails: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export const mapStateToProps = state => {
  return {
    basicproposal: state.createProposalData,
    isStandalone: state.loginData.isStandalone
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    updateProposalStateDataStatus: (proposalId, status) =>
      dispatch({
        type: "PROPOSAL_UPDATE_STATUS",
        proposalId: proposalId,
        status: status
      })
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Shell))
);
