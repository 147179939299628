import agencySearchService from "./agencySearchService";

export const AGENCY_SEARCH_RESULTS = "AGENCY_SEARCH_RESULTS";

export function agencySearch({ agencyName, marketId, altAgencyId, billAndRemit }) {
  return async dispatch => {
    const response = await agencySearchService.agencySearch({
      agencyName,
      marketId,
      altAgencyId,
      billAndRemit
    });
    dispatch({
      type: AGENCY_SEARCH_RESULTS,
      payload: response.result
    });
  };
}
