export const ERROR_CONSTANTS = {
  errorBoundary: {
    title: "wrong",
    subTitle: "Something went",
    content: "This page didn't load correctly. See the Javascript console for technical details."
  },
  errorOverlay: {
    moreDetails: "More details"
  }
};
