//
import React from "react";
import { Provider } from "react-redux";
import { StylesProvider } from "@material-ui/styles";
import { CookiesProvider } from "react-cookie";
import { withOAuth } from "aws-amplify-react";
import Amplify from "aws-amplify";

import store from "./store/Store";
import Routes from "./routes";
import GlobalCSS from "./GlobalCss";
import IFrameListener from "./components/shell/IFrameListener";
import ErrorBoundary from "./components/commons/error-boundary/ErrorBoundary";
import { amplifyConfig } from "./api-config/amplifyConfig";

Amplify.configure(amplifyConfig);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <IFrameListener />
        <StylesProvider>
          <CookiesProvider>
            <GlobalCSS />
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </CookiesProvider>
        </StylesProvider>
      </Provider>
    );
  }
}

export default withOAuth(App);
