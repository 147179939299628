import { withStyles } from "@material-ui/core/styles";

const GlobalCSS = withStyles({
  "@global": {
    ".btn-default": {
      borderRadius: "24.5px",
      color: "#29ACDC",
      textTransform: "none",
      boxShadow: "none",
      fontWeight: 500,
      fontSize: "14px",
      border: "0.5px solid #1BABDE",
      "&:hover, &:focus": {
        boxShadow: "none",
        outline: "none",
      },
    },
    ".MuiOutlinedInput-root.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0B7EB5",
        borderWidth: "0.5px",
      },
    },
    ".MuiOutlinedInput-root": {
      borderColor: "#A5A5A5",
      borderWidth: "0.5px",
      "& .MuiSelect-icon": {
        color: "#A5A5A5",
      },
    },
    ".MuiFormLabel-root": {
      color: "#585858",
      "&.Mui-focused": {
        color: "#0B7EB5",
        "&.Mui-error": {
          color: "#D8000C",
        },
      },
    },
    ".MuiFormHelperText-root": {
      color: "#D8000C",
      fontSize: 12,
    },
    ".MuiFormHelperText-contained": {
      margin: "4px 4px 10px",
    },
    ".MuiOutlinedInput-root.Mui-error": {
      color: "#D8000C",
      "& .MuiSelect-icon": {
        color: "#D8000C",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D8000C",
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#A5A5A5",
      borderWidth: "1.5px",
      "&:hover, &:focus": {
        borderColor: "#0B7EB5",
      },
    },
    ".btn-secondary": {
      backgroundColor: "transparent",
      "&:hover, &:focus": {
        backgroundColor: "transparent",
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: "#29ACDC",
        border: "1px solid rgba(27, 171, 222, .5)",
        opacity: ".5",
      },
    },
    ".btn-primary": {
      backgroundColor: "#29ACDC",
      color: "#FDFDFD",
      "&:hover, &:focus": {
        backgroundColor: "#29ACDC",
      },
      "&:disabled": {
        backgroundColor: "#29ACDC",
        color: "#FDFDFD",
        border: "1px solid rgba(27, 171, 222, .5)",
        opacity: ".5",
      },
    },
  },
})(() => null);

export default GlobalCSS;
