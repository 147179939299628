import axios from "axios";
import ES6Promise from "es6-promise";
import _ from "lodash";
import uuidV4 from "uuid/v4";

import handleStatusMessage from "./handleStatusMessage";
import { config } from "../api-config/config";

ES6Promise.polyfill();

/**
 * Create an Axios Client with defaults
 */
let client;

function getClient() {
  if (sessionStorage.getItem("token")) {
    if (!client) {
      client = axios.create({
        baseURL: config.serverURL,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
      });
    }
    return client;
  } else {
    //only should happen once at login
    return axios.create({
      baseURL: config.serverURL,
    });
  }
}

/**
 * Request Wrapper with default success/error actions
 */
const request = async function (options) {
  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    let errorMessage = {
      error: {
        description: "",
        title: "",
        code: "",
        reqId: "",
      },
    };

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx

      if (error.response.status === 401 || error.response.status === 403) {
        if (window.location.pathname.indexOf("login") === -1) {
          window.location.href = "/login";
        }
      } else if (error.response.status === 404) {
        return error.response.data;
      }

      let errorresponse = error.response;

      errorMessage.error.description = handleStatusMessage(
        errorresponse.status,
        _.get(errorresponse, "data.error.description")
      );
      errorMessage.error.title = _.get(errorresponse, "data.error.title");
      errorMessage.error.code = _.get(errorresponse, "data.error.code");
    } else {
      // Something else happened while setting up the request
      // triggered the error
      errorMessage.error.description = error.message;
      errorMessage.error.title = "Unexpected error";
      errorMessage.error.code = _.get(error, "code");
    }

    // xreq id if present
    const reqId = _.get(error, "response.config.headers['x-request-id']", "");
    errorMessage.error.reqId = reqId;

    return errorMessage;
  };

  if (!options) {
    options = {};
  }

  if (!options.headers) {
    options.headers = {};
  }

  options.headers["x-request-id"] = uuidV4();

  return getClient()(options).then(onSuccess).catch(onError);
};

export default request;
