import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import "./errorOverlay.scss";
import ExpansionLayout from "../expansion/ExpansionPanel";
import bg_blue from "../../../assets/bg_blue.svg";
import buildings from "../../../assets/buildings.svg";
import aeroplane from "../../../assets/aeroplane.svg";
import parachute from "../../../assets/parachute.svg";
import alertError from "../../../assets/alert_error.svg";
import { ERROR_CONSTANTS } from "../../../property/constants/errorConstants";

class ErrorOverLay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false
    };
  }

  handleExpansion = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    const { title, subTitle, info } = this.props;
    let expansionContent = null;
    if (info) {
      expansionContent = (
        <Alert severity="error" icon={<img src={alertError} alt="alert-error" />}>
          {info}
        </Alert>
      );
    }

    return (
      <div className="error-overlay">
        <div className="content-panel">
          <div className="content-panel-image">
            <img src={aeroplane} alt="expand" />
          </div>
          {subTitle && <Typography variant="h5">{subTitle}</Typography>}
          {title && <Typography variant="h2">{title}</Typography>}
          {info && (
            <ExpansionLayout
              expandedStatus={this.state.showDetails}
              expansionActiveLink=""
              handleExpansion={this.handleExpansion}
              heading={ERROR_CONSTANTS.errorOverlay.moreDetails}
              expansionPanelDetails={expansionContent}
            />
          )}
        </div>
        <div className="image-panel">
          <div className="front">
            <img className="front-top" src={parachute} alt="parachute" />
            <br />
            <img src={buildings} alt="buildings" />
          </div>
          <div className="back">
            <img src={bg_blue} alt="bg_blue" />
          </div>
        </div>
      </div>
    );
  }
}
export default ErrorOverLay;
