import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import { config } from "./api-config/config";
import { datadogLogs } from "@datadog/browser-logs";
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

datadogLogs.init({
  clientToken: "pub89039c969fadface4e7b7729eed3dcb2",
  env: config.environment,
});

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
