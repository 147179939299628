import creativeDimensionsService from "./creativeDimensionsService";
import { API_STATUS } from "../../../property/constants/commonConstants";

export const LOAD_CREATIVE_DIMENSIONS = "LOAD_CREATIVE_DIMENSIONS";

export const loadCreativeDimensions = () => {
  return async dispatch => {
    const response = await creativeDimensionsService.loadCreativeDimensions();
    if (response.status === API_STATUS.SUCCESS) {
      dispatch({
        type: LOAD_CREATIVE_DIMENSIONS,
        payload: response.result.creativeDimensions
      });
    }
  };
};
