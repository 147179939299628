import React, { useState, useLayoutEffect } from "react";

export default function FrameTestPage() {
  const [tenant, setTenant] = useState(30);
  const [token, setToken] = useState("");
  const [uri, setUri] = useState("/proposals");
  const [go, setGo] = useState(false);
  useLayoutEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
      if (!event.data.source) {
        console.log("Parent event :", event);
      }
    }
  }, []);
  useLayoutEffect(() => {
    if (go) {
      setTimeout(function() {
        const frame = document.getElementsByTagName("iframe")[0].contentWindow;
        console.log("frame :", frame);
        frame.postMessage(
          {
            type: "ADD_ORIGIN",
            payload: {}
          },
          "*"
        );
      }, 3000);
    }
  }, [go]);
  const base = `${window.location.protocol}//${window.location.host}`;
  return (
    <div>
      <h1>Mediascape Login test</h1>
      <form>
        <div>
          <label htmlFor="tenant">Tenant:</label>{" "}
          <input
            id="tenant"
            type="number"
            value={tenant}
            onChange={e => {
              setGo(false);
              setTenant(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="token">Valid Token:</label>{" "}
          <input
            id="token"
            type="text"
            value={token}
            onChange={e => {
              setGo(false);
              setToken(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="uri">Uri:</label>{" "}
          <input
            id="uri"
            type="text"
            value={uri}
            onChange={e => {
              setGo(false);
              setUri(e.target.value);
            }}
          />
        </div>
        <button
          type="submit"
          onClick={e => {
            e.preventDefault();
            setGo(true);
          }}
        >
          Go!
        </button>
      </form>
      <hr />
      {go && <div>src: {`${base}/login/30/${token}?uri=${encodeURIComponent(uri)}`}</div>}
      {go && (
        <div style={{ width: "100%", height: "600px", overflow: "hidden" }}>
          <iframe
            src={`${base}/login/30/${token}?uri=${encodeURIComponent(uri)}`}
            title="mediascape"
            width="100%"
            height="618px"
          />
        </div>
      )}
    </div>
  );
}
