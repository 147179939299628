import { BILLING_AGENCY_SEARCH_RESULTS } from "./billingAgencySearchActions";

const initialState = {
  agencies: [],
  perPage: 10,
  total: 0,
  page: 1,
};

export const reducer = function (state, action) {
  if (!state) state = initialState;
  if (action.type === BILLING_AGENCY_SEARCH_RESULTS) {
    if (action.payload) return action.payload;
    else return state;
  }
  return state;
};
