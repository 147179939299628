//GA and Datadog are turned off for local development
//Server url can still use the "old" style REACT_APP_SERVER_URI without DEV but in general defaults to dev

export const config = {
  serverURL:
    process.env.REACT_APP_SERVER_URI ||
    "https://0gzwv618g4.execute-api.us-east-1.amazonaws.com/dev/api/v1.0",
  NXTServerURL: "https://{tenant}.dev.mktnxt.com",
  gaTrackingId: undefined,
  gaDebug: true,
  clientId: "2u6ean83qeu7ig3a3ihi3dl1oq",
  cognitoDomain: "marketron-dev.auth.us-east-1.amazoncognito.com",
  identityProvider: "marketron-dev.com",
  environment: "local",
  launchDarklyClientId: "5da4c4dddc79f008b44ff6d6",
  uploadCreativeServerUrl: "https://radigio-alb-dev.mediascape-dev.com/creative/api/v1.0/creatives",
  userPoolId: "us-east-1_s6bRlZd0d",
};
