import {
  GET_CREATIVE_LIB,
  GET_ORDER_DETAILS,
  LINES_FRM_INFO,
  UPLOAD_CREATIVE,
  UPDATE_CAMPAIGN_EXISTS,
  UPDATE_LINE_HISTORY
} from "./linesType";

const initialState = {
  selectedStep: 0,
  completedSteps: [],
  campaignExists: false,
  dateHistory: [],
  budgetHistory: []
};

//TODO Merge all these reducers into one reducer
//TODO The reducers should all use switch statements like every other reducer
export const updateLinesFrmInfo = (state, action) => {
  if (!state) state = initialState;
  switch (action.type) {
    case UPDATE_CAMPAIGN_EXISTS: {
      return { ...state, campaignExists: action.payload };
    }
    case LINES_FRM_INFO: {
      return { ...state, ...action.payload };
    }
    case UPDATE_LINE_HISTORY: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const creativeList = (state, action) => {
  if (!state) state = [];
  if (GET_CREATIVE_LIB !== action.type) {
    return state;
  }
  return action.payload;
};

export const order = (state, action) => {
  if (!state) state = {};
  if (GET_ORDER_DETAILS !== action.type) {
    return state;
  }
  return action.payload;
};

export const uploadedCreatives = (state, action) => {
  if (!state) state = [];
  if (UPLOAD_CREATIVE !== action.type) {
    return state;
  }
  return [...state, action.payload];
};
