import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function Home() {
  const isLoggedIn = useSelector(state => state.loginData.isLoggedIn);
  const history = useHistory();

  if (isLoggedIn) {
    history.push("/proposals");
  } else {
    history.push("/login");
  }

  return <></>;
}
