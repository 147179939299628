import { config as configLocal } from "./config.local";
import { config as configDev } from "./config.development";
import { config as configQa } from "./config.qa";
import { config as configStaging } from "./config.staging";
import { config as configProd } from "./config.production";
import { config as configQa1 } from "./config-qa1";
import { config as configQa2 } from "./config-qa2";
import { config as configQa3 } from "./config-qa3";
import { config as configQa4 } from "./config-qa4";
import { config as configQa0 } from "./config-nxtqa0";
import { config as configNxtQa1 } from "./config-nxtqa1";
import { config as configNxtQa2 } from "./config-nxtqa2";
import { config as configNxtDev22 } from "./config-nxtdev22";
import { config as configLocalK8s } from "./config.localK8s";
import { dynamicConfig } from "./dynamic";

function getConfig() {
  if (window.location.hostname.indexOf("localhost") !== -1 && window.location.port === "30000")
    return configLocalK8s;
  if (window.location.hostname.indexOf("lvh.me") !== -1) return configLocalK8s;
  if (window.location.hostname.indexOf("localhost") !== -1) return configLocal;
  if (window.location.hostname.indexOf("nxtqa0") !== -1) return configQa0;
  if (window.location.hostname.indexOf("nxtqa1") !== -1) return configNxtQa1;
  if (window.location.hostname.indexOf("nxtqa2") !== -1) return configNxtQa2;
  if (window.location.hostname.indexOf("nxtdev22") !== -1) return configNxtDev22;
  if (window.location.hostname.indexOf("acme.mc3.local") !== -1) return configLocal;
  if (window.location.hostname.indexOf("frontend-dev") !== -1) return configDev;
  if (window.location.hostname.indexOf("frontend-staging") !== -1) return configStaging;
  if (window.location.hostname.indexOf("frontend-qa1") !== -1) return configQa1;
  if (window.location.hostname.indexOf("frontend-qa2") !== -1) return configQa2;
  if (window.location.hostname.indexOf("frontend-qa3") !== -1) return configQa3;
  if (window.location.hostname.indexOf("frontend-qa4") !== -1) return configQa4;
  if (window.location.hostname.indexOf("frontend-qa") !== -1) return configQa;
  if (
    window.location.hostname.indexOf("-dyn-") !== -1 ||
    window.location.hostname.indexOf("digital.lvh.me") !== -1
  )
    return dynamicConfig(window.location.hostname);
  return configProd;
}

const conf = getConfig();

export const config = {
  ...conf,
  agGridToken:
    "CompanyName=MBS OPCO, LLC,LicensedApplication=Marketron NXT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=9,LicensedProductionInstancesCount=1,AssetReference=AG-007903,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==b24c6d9c8e16f9a6de82e8a465187342",
  mapBoxAccessToken:
    "pk.eyJ1Ijoic2FuZGVlcHBhbXVqdWxhIiwiYSI6ImNqdjdwYzZzMzAyNGgzem14anNrYnV4YTUifQ.uHNzkTWmk9pap9kh8CkbXQ",
  region: "us-east-1",
};
