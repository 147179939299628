import { UPDATE_SELECTED_IMAGE, RESET_MEDIA } from "../actions/types";

const initialState = {
  selectedCreatives: []
};
export const updateSelectedImageReducer = (state, action) => {
  if (!state) state = initialState;
  switch (action.type) {
    case UPDATE_SELECTED_IMAGE:
    case RESET_MEDIA:
      const { imgStoreKey, images } = action.payload;
      return {
        ...state,
        ...{
          [imgStoreKey]: images
        }
      };

    default:
      return state;
  }
};
