import request from "../../../utils/request";
import URL from "../../../api-config/URLConfig";
import { ACCOUNT_LOOKUP } from "../../../property/constants/dialogConstants";

function advertiserSearch({ marketId, advertiserName, isDirect, billAndRemit }) {
  let data = {
    advertiserName,
    isDirect
  };
  if (!data.isDirect) {
    data = {
      ...data,
      billAndRemit
    };
  }

  return request({
    url: `${URL.ADVERTISER_SEARCH}/${marketId}`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json"
    },
    json: true
  });
}

function lookupAdvertiserSearch({
  advertiserName,
  marketId,
  businessType,
  billAndRemit,
  prospectAccounts,
  advertiserAddress,
  advertiserNameType,
  advertiserAddressType,
  page = 1,
  perPage = 10
}) {
  let data = {};
  if (businessType !== ACCOUNT_LOOKUP.BUSINESS_TYPE_ALL) {
    data = {
      ...data,
      isDirect: businessType === ACCOUNT_LOOKUP.BUSINESS_TYPE_DIRECT ? true : false
    };
  }

  if (advertiserName) {
    data = {
      ...data,
      advertiserName,
      nameSearchType: advertiserNameType
    };
  }

  if (advertiserAddress) {
    data = {
      ...data,
      address: advertiserAddress,
      addressSearchType: advertiserAddressType
    };
  }

  if (prospectAccounts !== ACCOUNT_LOOKUP.ANY_VALUE) {
    data = {
      ...data,
      prospectsOnly: prospectAccounts === ACCOUNT_LOOKUP.YES_VALUE ? true : false
    };
  }

  if (
    (businessType === ACCOUNT_LOOKUP.BUSINESS_TYPE_ALL ||
      businessType === ACCOUNT_LOOKUP.BUSINESS_TYPE_NON_DIRECT) &&
    billAndRemit !== ACCOUNT_LOOKUP.ANY_VALUE
  ) {
    data = {
      ...data,
      billAndRemit: billAndRemit === ACCOUNT_LOOKUP.YES_VALUE ? true : false
    };
  }

  return request({
    url: `${URL.ADVERTISER_SEARCH}/${marketId}?page=${page}&perPage=${perPage}`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/json"
    },
    json: true
  });
}

const advertiserSearchService = {
  advertiserSearch,
  lookupAdvertiserSearch
};

export default advertiserSearchService;
