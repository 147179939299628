export const metersToPixelsAtMaxZoom = (meters, latitude) => {
  return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
};

export const getCirclePaint = (radius, lat) => {
  const circlePaint = {
    "circle-radius": {
      stops: [
        [0, 0],
        [20, metersToPixelsAtMaxZoom(radius * 1609.34, lat)]
      ],
      base: 2
    },
    "circle-color": "rgba(83,214,240,0.69)",
    "circle-opacity": 0.2,
    "circle-stroke-color": "rgba(41,172,220,0.28)",
    "circle-stroke-width": 1
  };
  return circlePaint;
};

export const polygonPaint = {
  "fill-color": "#6F788A",
  "fill-opacity": 0.7
};
