import { config } from "./config";

const URLConfig = {
  PROPOSALS: config.serverURL + "/proposals",
  ADVERTISERS: config.serverURL + "/advertisers",
  LIST_ORDERS: config.serverURL + "/orders",
  ORDERS: config.serverURL + "/orders",
  LIST_INDUSTRY: config.serverURL + "/industries",
  CAMPAIGNS: config.serverURL + "/campaigns",
  MEDIA_TYPES: config.serverURL + "/mediatypes",
  COPY_PROPOSAL: config.serverURL + "/proposals/copy",
  REVERSE_GEOCODE: config.serverURL + "/geography/reversegeocodes/",
  ZIPCODES: config.serverURL + "/geography/zipcodes/",
  GEOCODE_URL: config.serverURL + "/geography/geocodes/",
  RADIUS_URL: config.serverURL + "/geography/zipcodes/radius/",
  BOUNDARIES_URL: config.serverURL + "/geography/boundaries/",
  AUDIENCE_URL: config.serverURL + "/audienceinterests",
  MEDIA_XML_UPLOAD: config.serverURL + "/mediaschedules/radio/schedules/calculate",
  GET_ALL_CREATIVES: config.serverURL + "/creatives",
  CAMPAIGN_CREATE: config.serverURL + "/campaigns/create",
  LOGIN_URL: config.serverURL + "/auth/login",
  DEMOGRAPHICS: config.serverURL + "/targetaudiences",
  REACH_FREQUENCY: config.serverURL + "/estimates/reach-frequency",
  POPULATION_ESTIMATION: config.serverURL + "/estimates/population",
  VALIDATE_TOKEN: config.serverURL + "/auth/validateToken",
  ORDER_LOOK_UP: "/orders/lookups",
  GET_ORDER_BY_ID: "/orders",
  //These below URLs will be changed once the api is deployed in dev server
  PPT_URL: config.serverURL + "/ppt",
  PDF_URL: config.serverURL + "/pdf",
  LIST_SALES_PERSON: config.serverURL + "/salespersons",
  BILLING_OPTIONS: config.serverURL + "/billing_options", // TO DO
  ORDERS_LOOKUPS: config.serverURL + "/orders/lookups",
  LIST_MARKETS: config.serverURL + "/markets",
  ADVERTISER_SEARCH: config.serverURL + "/advertisers/search",
  MEDIA_CAMPAIGN: config.serverURL + "/mediacampaigns",
  CPM_BY_MARKET_ID: config.serverURL + "/tenants/markets",
  CAMPAIGN_REPORT_LINKS: "reportLinks",
  LIST_CREATIVE_DIMENSIONS: config.serverURL + "/creativedimensions",
  UPLOAD_CREATIVE: config.uploadCreativeServerUrl,
  USER: config.serverURL + "/user",
  GOALS: config.serverURL + "/goals",
  FAVORITES: config.serverURL + "/campaigns/favorites",
  LIST_STATE_URL: config.serverURL + "/geography/states/search",
  LIST_DMA_URL: config.serverURL + "/geography/dma/search",
  OTT_ZIP_CODES_STATES: config.serverURL + "/geography/boundaries/states/",
  GET_DMA_URL: config.serverURL + "/geography/boundaries/dma",
  AGENCY_SEARCH: config.serverURL + "/agencies",
  CAMPAIGN_AUDIT_TRAIL_LIST: config.serverURL + "/campaigns/dsp-campaigns/",
  LIST_COUNTY_URL: config.serverURL + "/geography/counties/search",
  GET_COUNTY_URL: config.serverURL + "/geography/boundaries/counties",
  VALIDATE_CREATIVES: config.serverURL + "/creatives/validation",
  PROSPECT_CREATION_SERV_URL: "/api/advertisers/create_prospect",
};

export default URLConfig;
